import React, { FC, useCallback, createContext, useEffect, useMemo, useState, useContext } from 'react'
import { useGetProfileLazyQuery } from '../../graphql/generated/profile.generated'
import { UserPropsFragment } from '../../graphql/generated/types.generated'
import { LocalStorageItem } from '../../static/enums/LocalStorageItem'
import { Opt } from '../models/Opt'

interface Context {
  isAuthorized: boolean
  role: string
  currentUser?: UserPropsFragment
  setToken: (token: Opt<string>) => void
}

const AuthContext = createContext<Context | undefined>(undefined)

export const AuthContextProvider: FC = props => {
  const { children } = props

  const [token, setToken] = useState<string>()

  const isAuthorized = useMemo(() => !!token, [token])
  const [currentUser, setCurrentUser] = useState<UserPropsFragment>()

  const [getUser, { data: userData }] = useGetProfileLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!process.browser) {
      return
    }
    setToken(localStorage.getItem(LocalStorageItem.TOKEN) ?? undefined)
  }, [])

  useEffect(() => {
    if (!userData) {
      return
    }

    setCurrentUser(userData.me!)
  }, [userData])

  useEffect(() => {
    if (token) {
      getUser()
      setCurrentUser(undefined)
      localStorage.setItem(LocalStorageItem.TOKEN, token)
      return
    }

    setCurrentUser(undefined)
    localStorage.removeItem(LocalStorageItem.TOKEN)
  }, [token])

  const data = {
    isAuthorized,
    role: '',
    currentUser,
    setToken,
  }

  return <AuthContext.Provider value={data}> {children} </AuthContext.Provider>
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error("Auth context isn't initialized")
  }

  return context
}
