import type { FC } from 'react'
import React from 'react'
import SlickSlider, { Settings } from 'react-slick'

interface SliderProps {
  settings: Settings
  className?: string
}

export const Slider: FC<SliderProps> = props => {
  const { children, settings, className } = props

  return (
    <SlickSlider {...settings} className={className}>
      {children}
    </SlickSlider>
  )
}
