import React, { FC, useCallback } from 'react'
import { useState } from 'react'
import { FormGroup } from '../FormGroup'
import { InputProps } from '../../InputProps'
import styles from '../../form-components.module.scss'

export interface PasswordInputProps extends InputProps<string> {}

export const PasswordInput: FC<PasswordInputProps> = props => {
  const { value, placeholder, label, required, onChange, validationResult } = props

  const [visible, setVisible] = useState<boolean>(false)
  const [isTouched, setIsTouched] = useState(false)
  const onBlur = useCallback(() => setIsTouched(true), [])

  const toggleVisible = (): void => {
    setVisible(!visible)
  }

  return (
    <FormGroup
      label={label}
      validationResult={validationResult}
      required={required}
      isTouched={isTouched}
      children={
        <>
          <input
            type={visible ? 'text' : 'password'}
            required={required}
            value={value || ''}
            placeholder={placeholder || ''}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
          />
          <div className={`${styles.blockLogo} ${visible ? 'icon-eye-active' : 'icon-eye'}`} onClick={toggleVisible} />
        </>
      }
    />
  )
}
