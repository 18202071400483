import React, { FC, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Path } from '../../static/enums/Path'
import styles from './footer.module.scss'
import FacebookIcon from '../../assets/components-icons/footer/facebook.svg'
import TwitterIcon from '../../assets/components-icons/footer/twitter.svg'
import LinkIcon from '../../assets/components-icons/footer/link.svg'
import GoogleIcon from '../../assets/components-icons/footer/google.svg'

interface Props {
  menuItems: MenuItem[]
  logoLink?: boolean
  setVisiblePrivacyPolicyModal: (val: boolean) => void
}

export const Footer: FC<Props> = props => {
  const { menuItems, logoLink, setVisiblePrivacyPolicyModal } = props

  const { push } = useRouter()

  const privacyPolicyModal = useCallback(() => setVisiblePrivacyPolicyModal(true), [setVisiblePrivacyPolicyModal])

  return (
    <footer className={`container ${styles.container}`}>
      <div className={styles.topContentContainer}>
        <div className={`${styles.menuList} ${styles.top}`}>
          {logoLink ? (
            <div className={`${styles.logoBlock} ${styles.logoDefault}`} />
          ) : (
            <div onClick={() => push(Path.SEARCH)} className={`${styles.logoBlock}`} />
          )}
          {/* <ul className={`${styles.linksWrapper}`}>
            {menuItems.map(item => (
              <li className={`${styles.linksFooter}`} key={item.id}>
                {item.label}
              </li>
            ))}
          </ul> */}
        </div>
        {/* <div className={`${styles.socialWrapper}`}>
          <div className={`${styles.iconBlock} `}>
            <FacebookIcon />
          </div>
          <div className={`${styles.iconBlock}`}>
            <TwitterIcon />
          </div>
          <div className={`${styles.iconBlock}`}>
            <LinkIcon />
          </div>
          <div className={`${styles.iconBlock}`}>
            <GoogleIcon />
          </div>
        </div> */}
      </div>
      <div className="divider" />
      <div className={styles.bottomContentContainer}>
        <div className={styles.bottomPolicy}>
          <span className={styles.AllRights}>2021 MapViser. All rights reserved. </span>
          <span className={styles.textBlack} onClick={privacyPolicyModal}>
            Privacy Policy
          </span>
        </div>
        <div className={styles.developWrapper}>
          <a href="https://bonum-studio.com/" target="_blank">
            <span className={styles.developText}>Developed by</span>
            <div className={styles.logoBlock} />
          </a>
        </div>
      </div>
    </footer>
  )
}

type MenuItem = {
  id: number
  label: string
}
