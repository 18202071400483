import React, { FC } from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'

interface Props {
  title?: string
  description?: string
  keywords?: string
  image?: string
}

export const Helmet: FC<Props> = props => {
  const { title, description, keywords, image } = props
  return (
    <ReactHelmet>
      <title>{title || 'Mapviser - Consumer-oriented product that helps people find the best treatment'}</title>
      <meta name="keywords" content={keywords || ''} />
      <meta
        name="description"
        content={
          description ||
          'Mapviser: Provides consumers with education ✔️Presents direct link of the desired treatment with experts of the world who provide such treatment, and the clinic sites where these experts work ✔️Allows to explore the available treatments in desired geographical location in direct proximity or wherever possible (for unique procedures and experts)'
        }
      />
      <meta
        name="og:description"
        content={
          description ||
          'Mapviser: Provides consumers with education ✔️Presents direct link of the desired treatment with experts of the world who provide such treatment, and the clinic sites where these experts work ✔️Allows to explore the available treatments in desired geographical location in direct proximity or wherever possible (for unique procedures and experts)'
        }
      />
      <meta
        name="og:title"
        content={title || 'Mapviser - Consumer-oriented product that helps people find the best treatment'}
      />
      <meta name="og:image" content={image || ''} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="" />
      <meta property="og:locale" content="en_US" />
      <meta property="article:modified_time" content="2021-06-02T05:58:27+00:00" />
      <meta property="og:image:width" content="2560" />
      <meta property="og:image:height" content="2560" />
      <meta name="twitter:card" content="summary" />
    </ReactHelmet>
  )
}
