import { IValidator } from '../../../core/validation/IValidator'
import { StringValidator } from '../../../core/validation/StringValidator'
import { useErrors } from '../../../core/forms/useErrors'
import { PasswordInput } from '../../../core/forms/components/PasswordInput'
import { useProperty } from '../../../core/hooks/useProperty'
import { Button, ButtonsColors, ButtonsSizes } from '../../../core/components/Button'

import React, { FC } from 'react'
import { TextInput } from '../../../core/forms/components/TextInput'
import styles from './loginForm.module.scss'
import { useRouter } from 'next/router'
import { Path } from '../../../static/enums/Path'
interface Props {
  initialData: LoginFormData | undefined
  onSubmit: (formData: LoginFormData) => void
  localization: LoginFormLocalization
}

export const LoginForm: FC<Props> = props => {
  const { initialData, onSubmit, localization } = props
  const { push } = useRouter()
  const { labels } = localization

  const [email, setEmail, emailValidationResult] = useProperty(initialData?.email, labels.email, emailValidator)
  const [password, setPassword, passwordValidationResult] = useProperty(
    initialData?.password,
    labels.password,
    passwordValidator
  )

  const { hasError } = useErrors(emailValidationResult, passwordValidationResult)

  const onOkClick = () => {
    if (hasError) {
      return
    }

    const formData = {
      email,
      password,
    } as LoginFormData

    onSubmit(formData)
  }
  return (
    <div className={styles.container}>
      <h2>{localization.login}</h2>
      <TextInput
        required
        label={labels.email}
        value={email}
        validationResult={emailValidationResult}
        onChange={setEmail}
      />
      <PasswordInput
        required
        label={labels.password}
        value={password}
        validationResult={passwordValidationResult}
        onChange={setPassword}
      />
      <div className={styles.text}>
        <span className={styles.link}>{localization.forgot}</span>
      </div>
      <div className={styles.button}>
        <Button
          color={ButtonsColors.BLUE}
          size={ButtonsSizes.MEDIUM}
          disabled={hasError}
          onClick={onOkClick}
          text={localization.okButton}
        />
      </div>
      <div className={styles.text}>
        <span>{localization.dontHaveAccount}</span>
        <span className={styles.link} onClick={() => push(Path.REGISTER)}>
          {' '}
          {localization.signUp}
        </span>
      </div>
    </div>
  )
}

export interface LoginFormData {
  email: string
  password: string
}

export interface LoginFormLocalization {
  labels: {
    email: string
    password: string
  }
  okButton: string
  signUp: string
  dontHaveAccount: string
  forgot: string
  login: string
}

const emailValidator: IValidator<string> = (value, displayName) => {
  const validator = new StringValidator(value, displayName, {
    shouldExists: true,
    shouldBeEmail: true,
  })

  return validator.validate()
}
const passwordValidator: IValidator<string> = (value, displayName) => {
  const validator = new StringValidator(value, displayName, {
    shouldExists: true,
  })

  return validator.validate()
}
