import { StringUtil } from '../utils/StringUtil'
import { ValidationConditionConfig, ValidationParameteredConditionConfig } from './ValidationConditionConfig'

export abstract class BaseValidator<TValue, TValidatorMessages extends BaseValidationConfig<TValue>> {
  protected propertyName: string
  /**
   * Getter of this property will throw an error if value is undefined
   * Please check shouldExists before getting of value
   */
  protected get value() {
    if (this._value === undefined) {
      throw new Error(`Value for ${this.propertyName} doesn't exist`)
    }

    return this._value
  }
  private _value: TValue | undefined
  protected config: TValidatorMessages
  protected replacerMap: Map<string, string>

  constructor(value: TValue | undefined, propertyName: string, messages: TValidatorMessages) {
    this._value = value
    this.propertyName = propertyName
    this.config = messages
    this.replacerMap = new Map<string, string>()
    this.replacerMap.set('propertyName', this.propertyName)
    this.replacerMap.set('value', value + '')
  }
  protected shouldExists() {
    const { shouldExists } = this.config

    var test = () => this._value === undefined

    return this.getMessage(test, shouldExists)
  }

  protected getParametredMessage<TData>(
    test: (data: TData) => boolean,
    config: ValidationParameteredConditionConfig<TValue, TData> | undefined
  ): string | undefined {
    if (!config) {
      return undefined
    }

    const hasError = test(config.data)

    if (!hasError) {
      return undefined
    }

    const { message, data } = config

    if (typeof message === 'string') {
      return StringUtil.replace(message, this.replacerMap)
    }

    return message(this.value, this.propertyName, data)
  }

  protected getMessage(test: () => boolean, config: ValidationConditionConfig<TValue> | undefined): string | undefined {
    if (!config) {
      return undefined
    }

    const hasError = test()

    if (!hasError) {
      return undefined
    }

    const { message } = config

    if (typeof message === 'string') {
      return StringUtil.replace(message, this.replacerMap)
    }

    return message(this.value, this.propertyName)
  }
}
//TODO: add message about anupporiate simbols
export interface BaseValidationConfig<T> {
  shouldExists?: ValidationConditionConfig<T>
}

export const defaultBaseValidationMessages = {
  shouldExists: 'Property {propertyName} is required',
}
