import React, { FC, useCallback, useState } from 'react'
import { FormGroup } from '../FormGroup'
import { InputProps } from '../../InputProps'

export interface TextInputProps extends InputProps<string> {}

export const TextInput: FC<TextInputProps> = props => {
  const { value, hideError, placeholder, label, required, onChange, validationResult } = props
  const [isTouched, setIsTouched] = useState(false)
  const onBlur = useCallback(() => setIsTouched(true), [])

  return (
    <FormGroup
      label={label}
      validationResult={!hideError ? validationResult : undefined}
      required={required}
      isTouched={isTouched}
      children={
        <>
          {/*<button className={`${styles.searchIcon} icon-search-grey`} />*/}
          <input
            // className={styles.searchInput}
            type="text"
            required={required}
            value={value || ''}
            placeholder={placeholder || ''}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
          />
        </>
      }
    />
  )
}
