import { ValidationConditionConfig, ValidationParameteredConditionConfig } from './ValidationConditionConfig'
import { ValidationMessage, ValidationParameteredMessage } from './ValidationMessage'

export interface VCC1<TValue, TData> {
  message?: ValidationParameteredMessage<TValue, TData>
  data: TData
}

export interface VCC<TValue> {
  message?: ValidationMessage<TValue>
}

export type S<TValue> = boolean | VCC<TValue>

export function parseConfig<TValue>(
  config: S<TValue> | undefined,
  message: ValidationMessage<TValue>
): ValidationConditionConfig<TValue> | undefined {
  if (!config) {
    return undefined
  }

  if (typeof config === 'boolean' && config === true) {
    return {
      message,
    }
  }

  return {
    message,
    ...config,
  }
}

export function parseConfig1<TValue, TData>(
  config: VCC1<TValue, TData> | undefined,
  message: ValidationParameteredMessage<TValue, TData>
): ValidationParameteredConditionConfig<TValue, TData> | undefined {
  if (!config) {
    return undefined
  }
  return {
    message,
    ...config,
  }
}
