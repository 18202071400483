import React from 'react'
import type { FC } from 'react'
import styles from './modal.module.scss'

interface Props {
  visibleModal: boolean
  setVisibleLoginModal: (val: boolean) => void
}
export const Modal: FC<Props> = props => {
  const { children, visibleModal, setVisibleLoginModal } = props

  return (
    <div className={`${styles.container} ${!visibleModal ? styles.dNone : ''}`}>
      <div className={styles.overlay} onClick={() => setVisibleLoginModal(false)} />
      <div className={styles.modal}>
        <i className={`icon icon-close-modal ${styles.close}`} onClick={() => setVisibleLoginModal(false)} />
        {children}
      </div>
    </div>
  )
}
