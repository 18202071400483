export const StringUtil = {
  replace: (str: string, values: Map<string, string>) => {
    var keys = values.keys()

    for (var key of keys) {
      var val = values.get(key)
      if (!val) {
        continue
      }
      str = str.replace(new RegExp('{' + key + '}', 'gi'), val)
    }

    return str
  },
}
