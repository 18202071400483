import { useRouter } from 'next/router'
import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { useAuthContext } from '../../core/auth/AuthContext'
import { ButtonsColors, ButtonsSizes, Button } from '../../core/components/Button'
import { Path } from '../../static/enums/Path'
import { PersonalPath } from '../../static/enums/PersonalPath'
import styles from './header.module.scss'
import FacebookIcon from '../../assets/components-icons/footer/facebook.svg'
import TwitterIcon from '../../assets/components-icons/footer/twitter.svg'
import LinkIcon from '../../assets/components-icons/footer/link.svg'
import GoogleIcon from '../../assets/components-icons/footer/google.svg'

interface Props {
  menuItems: MenuItem[]
  signIn?: boolean
  logoLink?: boolean
  setVisibleLoginModal: (val: boolean) => void
}

export const Header: FC<Props> = props => {
  const { menuItems, signIn, setVisibleLoginModal, logoLink } = props

  const { setToken } = useAuthContext()
  const { push } = useRouter()
  const onSignIn = useCallback(() => setVisibleLoginModal(true), [setVisibleLoginModal])

  const onSignOut = useCallback(() => {
    setToken(undefined)
    push(Path.SEARCH)
  }, [setToken, push])

  const onPersonalClick = useCallback(() => {
    push(PersonalPath.PROFILE)
  }, [push])

  const onSearchClick = useCallback(() => {
    push(Path.SEARCH)
  }, [push])

  //mobile menu
  const [activeMobMenu, setActiveMobMenu] = useState<boolean>(false)
  const { isAuthorized } = useAuthContext()

  const toggleMobileMenu = useCallback(() => {
    setActiveMobMenu(prev => {
      !prev ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll')
      return !prev
    })
  }, [])

  useEffect(() => {
    if (activeMobMenu) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [])

  const goToMain = useCallback(() => {
    push(Path.SEARCH)
  }, [push])

  const logoComponent = useCallback(() => {
    if (logoLink) {
      return <div className={`${styles.logoBlock} ${styles.logoDefault}`} />
    }
    return <div onClick={goToMain} className={`${styles.logoBlock}`} />
  }, [logoLink])

  //dropdown state
  const [activeDropdownState, setActiveDropdownState] = useState<boolean>(true)

  const toggleDropdownState = useCallback(() => {
    setActiveDropdownState(prev => {
      setStorageState()
      return !prev
    })
  }, [])

  function dismissState() {
    setActiveDropdownState(false)
    document.body.classList.remove('no-scroll')
    setActiveMobMenu(false)
  }

  const dismissDropdownState = useCallback(() => {
    dismissState()
    setStorageState()
    setDismissStateStorage(true)
  }, [])

  //storage disable show dropdown states
  const setStorageState = () => {
    localStorage.setItem('dismissState', 'true')
  }

  const [dismissStateStorage, setDismissStateStorage] = useState<boolean>(false)

  useEffect(() => {
    if (localStorage.getItem('dismissState')) {
      dismissState()
    }
  }, [])

  return (
    <header className={`${styles.container}`}>
      <div className={styles.blockBg} />

      <div className={`container ${styles.contentContainer}`}>
        <div className={styles.mobileMenuList}>
          {activeMobMenu ? (
            <div className={`icon icon-close-menu ${styles.closeMobMenu}`} onClick={toggleMobileMenu} />
          ) : (
            <>
              <div className={styles.burgerMenuBlock} onClick={toggleMobileMenu} />
            </>
          )}
        </div>
        {logoComponent()}

        <div className={`${styles.menuList} ${activeMobMenu ? `${styles.openedMenu}` : ''}`}>
          <div className={styles.menuAlignSpaceMobile}>
            <div className={styles.menuListWrapper}>
              <ul className={styles.listMenu}>
                {menuItems.map(item => (
                  <li onClick={() => location.href = (item.url)} className={styles.headerLink} key={item.id}>
                    {item.label}
                  </li>
                ))}
              </ul>

              {/* <span className={styles.stateWrapper}>
                <span
                  className={`${styles.stateName} 
                                    ${activeDropdownState ? `${styles.dropdownVisible}` : ''}`}
                  onClick={toggleDropdownState}
                >
                  Massachusetts
                </span>
                {activeDropdownState && (
                  <span className={styles.stateBody}>
                    <span className={styles.stateClose} onClick={dismissDropdownState}>
                      <i className={`icon icon-close`} />
                    </span>

                    <span className={styles.stateQuestion}>
                      Are you in <span className={styles.stateBlue}>Massachusetts?</span>
                    </span>

                    <span className={styles.stateButton}>
                      <Button
                        onClick={dismissDropdownState}
                        text={'Yes'}
                        color={ButtonsColors.BLUE}
                        size={ButtonsSizes.LARGE}
                      />
                    </span>

                    <a href="#statesFooter" className={styles.stateChangeLocation} onClick={dismissDropdownState}>
                      Change location
                    </a>
                  </span>
                )}
              </span> */}
            </div>

            {/* <div className={`${styles.socialWrapper}`}>
              <div className={`${styles.iconBlock} `}>
                <FacebookIcon />
              </div>
              <div className={`${styles.iconBlock}`}>
                <TwitterIcon />
              </div>
              <div className={`${styles.iconBlock}`}>
                <LinkIcon />
              </div>
              <div className={`${styles.iconBlock}`}>
                <GoogleIcon />
              </div>
            </div> */}
          </div>
        </div>

        {activeMobMenu && (
          <div className={`${styles.backdropMenu} ${styles.visibleBackdrop}`} onClick={toggleMobileMenu} />
        )}

        {/*TODO: #for_blog*/}
        {/*{signIn && isAuthorized && (*/}
        {/*  <Button text={'Personal'} color={ButtonsColors.SUCCESS} size={ButtonsSizes.SMALL} onClick={onPersonalClick} />*/}
        {/*)}*/}
        {/*{signIn ? (*/}
        {/*  !isAuthorized ? (*/}
        {/*    <Button*/}
        {/*      text="Sign in"*/}
        {/*      color={ButtonsColors.TRANSPARENT_WHITE}*/}
        {/*      size={ButtonsSizes.SMALL}*/}
        {/*      onClick={onSignIn}*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <Button*/}
        {/*      text="Sign out"*/}
        {/*      color={ButtonsColors.TRANSPARENT_WHITE}*/}
        {/*      size={ButtonsSizes.SMALL}*/}
        {/*      onClick={onSignOut}*/}
        {/*    />*/}
        {/*  )*/}
        {/*) : (*/}
        {/*  <Button*/}
        {/*    leftIcon="icon-search"*/}
        {/*    text="New search"*/}
        {/*    color={ButtonsColors.SUCCESS}*/}
        {/*    size={ButtonsSizes.SMALL}*/}
        {/*    onClick={onSearchClick}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </header>
  )
}

export type MenuItem = {
  id: number
  label: string
  url: string
}
