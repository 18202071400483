import * as Types from './types.generated'

import * as Operations from './types.generated'
import * as Apollo from '@apollo/client'
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client'
import type React from 'react'
import { getApolloClient } from '../apolloClient'

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>
) {
  return Apollo.useQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(Operations.GetProfile, baseOptions)
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>
) {
  return Apollo.useLazyQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(Operations.GetProfile, baseOptions)
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>
export type GetProfileQueryResult = Apollo.QueryResult<Types.GetProfileQuery, Types.GetProfileQueryVariables>
export async function getServerPageGetProfile(
  options: Omit<Apollo.QueryOptions<Types.GetProfileQueryVariables>, 'query'>,
  ctx?: any
) {
  const apolloClient = getApolloClient(ctx)

  const data = await apolloClient.query<Types.GetProfileQuery>({ ...options, query: Operations.GetProfile })

  const apolloState = apolloClient.cache.extract()

  return {
    props: {
      apolloState: apolloState,
      data: data?.data,
      error: data?.error ?? data?.errors ?? null,
    },
  }
}
export const useGetProfile = (
  optionsFunc?: (router: NextRouter) => QueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>
) => {
  const router = useRouter()
  const options = optionsFunc ? optionsFunc(router) : {}
  return useQuery(Operations.GetProfile, options)
}
export type PageGetProfileComp = React.FC<{ data?: Types.GetProfileQuery; error?: Apollo.ApolloError }>
export const ssrGetProfile = {
  getServerPage: getServerPageGetProfile,

  usePage: useGetProfile,
}
