export enum Path {
  OCTAGON = '/octagon/',
  SEARCH = '/',
  REGISTER = '/registration',
  LOGIN = '/login',
  SPECIALIST_SPECIALTY = '/specialist/specialty',
  MV_NEWS = '/mvnews',
  MV_NEWS_CATEGORY = '/mvnews-category',
  MEDICAL_NEWS = '/posts',
  MEDICAL_NEWS_CATEGORY = '/posts-category',
  LOCAL_TRENDS = '/localtrends',
  LOCAL_TRENDS_CATEGORY = '/localtrends-category',
  DISEASES = '/diseases',
  DISEASES_CATEGORY = '/diseases-category',
  HELP = '/help',
  NOT_FOUND = '/404',
}
