import React, { FC, useState } from 'react'
import { Footer } from '../Footer'
import { Header, MenuItem } from '../Header'
import { StatesFooter } from '../StatesFooter'
import styles from './layout.module.scss'
import { LoginModal } from '../LoginModal'
import { LoginFormLocalization } from '../forms/LoginForm/LoginForm'
import { Path } from '../../static/enums/Path'
import { Helmet } from '../Helmet'
import { PrivacyPolicyModal } from '../PrivacyPolicyModal'

interface Props {
  footer?: boolean
  statesFooter?: boolean
  header?: boolean
  row?: boolean
  signIn?: boolean
  logoLink?: boolean
}

export const Layout: FC<Props> = props => {
  const { children, footer = true, header = true, statesFooter = false, row = false, signIn, logoLink } = props
  const [visibleLoginModal, setVisibleLoginModal] = useState<boolean>(false)
  const [visibleModalPolicy, setVisiblePrivacyPolicyModal] = useState<boolean>(false)

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperHead}>
          <Helmet />
          {header && (
            <Header
              menuItems={headerMenuItems}
              setVisibleLoginModal={setVisibleLoginModal}
              signIn={signIn}
              logoLink={logoLink}
            />
          )}
          <LoginModal
            localization={loginLocalization}
            visibleModal={visibleLoginModal}
            setVisibleLoginModal={setVisibleLoginModal}
          />
          <PrivacyPolicyModal
            visibleModalPolicy={visibleModalPolicy}
            setVisiblePrivacyPolicyModal={setVisiblePrivacyPolicyModal}
          />
          <div className={row ? styles.row : ''}>{children}</div>
          {statesFooter && (
            <StatesFooter
              localization={{
                title: 'Find pros in all',
                text: '',
                stateName: 'states',
              }}
              states={states}
            />
          )}
        </div>
        {footer && (
          <Footer
            setVisiblePrivacyPolicyModal={setVisiblePrivacyPolicyModal}
            menuItems={footerMenuItems}
            logoLink={logoLink}
          />
        )}
      </div>
    </>
  )
}

const loginLocalization: LoginFormLocalization = {
  labels: {
    email: 'Email',
    password: 'Password',
  },
  okButton: 'Login',
  signUp: 'Sign up',
  dontHaveAccount: 'Don’t have account?',
  forgot: 'Forgot your password?',
  login: 'Login',
}

const headerMenuItems: MenuItem[] = [
  // {
  //   id: 1,
  //   label: 'MV News',
  //   url: Path.MV_NEWS,
  // },
  // {
  //   id: 2,
  //   label: 'Local trends',
  //   url: Path.LOCAL_TRENDS,
  // },
  // {
  //   id: 3,
  //   label: 'Medical news',
  //   url: Path.MEDICAL_NEWS,
  // },
  {
    id: 4,
    label: 'Diseases',
    url: Path.DISEASES,
  },
  // {
  //   id: 5,
  //   label: 'Help',
  //   url: Path.HELP,
  // },
]

const footerMenuItems = [
  {
    id: 1,
    label: 'Treatment Trends',
  },
  {
    id: 2,
    label: 'Personal account',
  },
  {
    id: 3,
    label: 'Business account',
  },
]

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]
