import type { FC } from 'react'
import React from 'react'
import { ModalPolicy } from '../ModalPolicy'

interface Props {
  visibleModalPolicy: boolean
  setVisiblePrivacyPolicyModal: (val: boolean) => void
}

export const PrivacyPolicyModal: FC<Props> = props => {
  const { setVisiblePrivacyPolicyModal, visibleModalPolicy } = props

  return (
    <ModalPolicy visibleModalPolicy={visibleModalPolicy} setVisiblePrivacyPolicyModal={setVisiblePrivacyPolicyModal} />
  )
}
