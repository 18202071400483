import { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { IValidator } from '../validation/IValidator'
import { ValidationResult } from '../validation/ValidationResult'

export function useProperty<TValue>(
  initialValue: TValue | undefined,
  displayName: string,
  validator?: IValidator<TValue, ValidationResult>
) {
  const [value, setValue] = useState(initialValue)
  const [validation, setValidation] = useState<ValidationResult>()

  type Value = TValue | undefined
  type SetValue = Dispatch<SetStateAction<TValue | undefined>>
  type ValidationResultType = ValidationResult | undefined

  //TODO: add touched to understand should we show message or just have disabled button
  useEffect(() => {
    if (!validator) {
      return
    }
    const validation = validator(value, displayName)
    setValidation(validation)

    if (validation.hasError) {
      return
    }
  }, [validator, value, displayName])

  //TODO: maybe it should be configurable should value with error be setted or not

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const result: [Value, SetValue, ValidationResultType] = [value, setValue, validation]

  return result
}
