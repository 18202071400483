import React, { useEffect } from 'react'
import type { FC } from 'react'
import styles from './modalPolicy.module.scss'
import { Scrollbar } from 'react-scrollbars-custom'

interface Props {
  localization?: ModalPolicyLocalization
  visibleModalPolicy: boolean
  setVisiblePrivacyPolicyModal: (val: boolean) => void
}

export const ModalPolicy: FC<Props> = props => {
  const { visibleModalPolicy, setVisiblePrivacyPolicyModal } = props

  useEffect(() => {
    if (visibleModalPolicy) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  })

  return (
    <div className={`${styles.container} ${!visibleModalPolicy ? styles.dNone : ''}`}>
      <div className={styles.overlay} onClick={() => setVisiblePrivacyPolicyModal(false)} />
      <div className={styles.modal}>
        <i className={`icon icon-close-modal ${styles.close}`} onClick={() => setVisiblePrivacyPolicyModal(false)} />
        <Scrollbar className={styles.modalContent}>
          <h2>{localization.webPrivacy}</h2>
          <p>{localization.webPrivacyText}</p>
          <p>{localization.webPrivacyApply}</p>

          <br />

          <h2>{localization.informationCollectTitle}</h2>
          <p>{localization.informationCollectGiveUs}</p>
          <p>{localization.informationCollectReceive}</p>
          <p>{localization.informationCollectAutomatically}</p>
          <p>{localization.informationCollectCookies}</p>
          <p>{localization.informationCollectRelatedServices}</p>

          <br />

          <h2>{localization.weUseInformation}</h2>
          <p>{localization.weUseInformationDescription}</p>
          <ul>
            <li>{localization.weUseInformationList1}</li>
            <li>{localization.weUseInformationList2}</li>
            <li>{localization.weUseInformationList3}</li>
            <li>{localization.weUseInformationList4}</li>
            <li>{localization.weUseInformationList5}</li>
            <li>{localization.weUseInformationList6}</li>
            <li>{localization.weUseInformationList7}</li>
            <li>{localization.weUseInformationList8}</li>
            <li>{localization.weUseInformationList9}</li>
            <li>{localization.weUseInformationList10}</li>
          </ul>

          <br />

          <h2>{localization.dataRetention}</h2>
          <p>{localization.dataRetentionRetain}</p>
          <p>{localization.dataRetentionShare}</p>
          <p>{localization.dataRetentionCollect}</p>
          <p>{localization.dataRetentionParties}</p>
          <p>{localization.dataRetentionRequiredInformation}</p>

          <br />

          <h2>{localization.protectingChildren}</h2>
          <p>{localization.protectingChildrenCommitted}</p>

          <br />

          <h2>{localization.linksWebsites}</h2>
          <p>{localization.linksWebsitesDescription}</p>
        </Scrollbar>
      </div>
    </div>
  )
}

interface ModalPolicyLocalization {
  webPrivacy: string
  webPrivacyText: string
  webPrivacyApply: string
  informationCollectTitle: string
  informationCollectGiveUs: string
  informationCollectReceive: string
  informationCollectAutomatically: string
  informationCollectCookies: string
  informationCollectRelatedServices: string
  weUseInformation: string
  weUseInformationDescription: string
  weUseInformationList1: string
  weUseInformationList2: string
  weUseInformationList3: string
  weUseInformationList4: string
  weUseInformationList5: string
  weUseInformationList6: string
  weUseInformationList7: string
  weUseInformationList8: string
  weUseInformationList9: string
  weUseInformationList10: string
  dataRetention: string
  dataRetentionRetain: string
  dataRetentionShare: string
  dataRetentionCollect: string
  dataRetentionParties: string
  dataRetentionRequiredInformation: string
  protectingChildren: string
  protectingChildrenCommitted: string
  linksWebsites: string
  linksWebsitesDescription: string
}

const localization = {
  webPrivacy: 'Web privacy policy',
  webPrivacyText:
    'We take your privacy seriously, and we want you to know how we collect, use, share and protect your information.',
  webPrivacyApply: 'This policy applies to Mapviser.com.',
  informationCollectTitle: 'What information we collect',
  informationCollectGiveUs:
    'Information you give us: We respect the right to privacy of all visitors to the Mapviser.com.',
  informationCollectReceive:
    'We receive and store information you enter on our site or give us in any other way, such as name, email address and phone number. This includes information you submit on forms, such as appointment request forms. Some forms collect sensitive information, such as health information, necessary for us to provide our services to you.',
  informationCollectAutomatically:
    'Information we collect automatically: When you interact with our sites and email newsletters, certain information about your use of our sites and interaction with our email newsletters is automatically collected. This information includes computer and connection information, such as statistics on your page views, traffic to and from our sites, referral URL, ad data, your IP address, and device identifiers. This information also may include your transaction history, and your web log information, how you search for our sites, the websites you click on from our sites or emails, whether and when you open our emails, and your browsing activities across other websites.',
  informationCollectCookies:
    'Much of this information is collected through cookies, web beacons and other tracking technologies, as well as through your web browser or device (e.g., IP address, MAC address, browser version, etc.).',
  informationCollectRelatedServices: 'Email communications, newsletter and related services',
  weUseInformation: 'We use the information we collect for things like:',
  weUseInformationDescription: 'Optimizing the performance and user experience of our sites',
  weUseInformationList1: 'Operating, evaluating and improving our business.',
  weUseInformationList2: 'Fulfilling orders and requests for products, services or information.',
  weUseInformationList3: 'Processing returns and exchanges.',
  weUseInformationList4: 'Tracking and confirming online orders.',
  weUseInformationList5: 'Delivering or installing products.',
  weUseInformationList6:
    'Marketing and advertising products and services, including by inferring your interests from your interactions with our websites and newsletters, and tailoring advertisements, newsletters, and offers to you (both on our websites and on other websites) based on your interactions with us in our stores and online interests.',
  weUseInformationList7: 'Sending you email newsletters.',
  weUseInformationList8: 'Conducting research and analysis.',
  weUseInformationList9: 'Communicating with you about your account, special events and surveys.',
  weUseInformationList10: 'Establishing and managing your accounts with us.',
  dataRetention: 'Data retention',
  dataRetentionRetain:
    'We will retain your information for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes and enforce our agreements.',
  dataRetentionShare: 'We may share information with third parties.',
  dataRetentionCollect:
    'We may share the information we collect about you with third parties who we have engaged to help us provide the services, or they may collect information about you directly when you interact with them.',
  dataRetentionParties:
    "Third parties may collect information such as IP addresses, traffic patterns and related information. This data reflects site-usage patterns gathered during visits to our website each month or newsletter subscribers' interactions with our newsletters.",
  dataRetentionRequiredInformation:
    'We may also use or disclose your personal information if required to do so by law or on the good-faith belief that such action is necessary to (a) conform to applicable law or comply with legal process served on us or our sites; (b) protect and defend our rights or property, the sites, or our users; or (c) act to protect the personal safety of us, users of the sites or the public.',
  protectingChildren: "Protecting children's privacy",
  protectingChildrenCommitted:
    "We are committed to protecting children's privacy on the internet, and we do not knowingly collect personal information from children.",
  linksWebsites: 'Links to other websites',
  linksWebsitesDescription:
    "Our websites link to other websites, many of which have their own privacy policies. Be sure to review the privacy policy on the site you're visiting.",
}
