import * as Types from './types.generated'

import * as Operations from './types.generated'
import * as Apollo from '@apollo/client'
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client'
import type React from 'react'
import { getApolloClient } from '../apolloClient'

export type LoginMutationFn = Apollo.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>
) {
  return Apollo.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(Operations.Login, baseOptions)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<Types.LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>
export type RegisterAsMutationFn = Apollo.MutationFunction<Types.RegisterAsMutation, Types.RegisterAsMutationVariables>

/**
 * __useRegisterAsMutation__
 *
 * To run a mutation, you first call `useRegisterAsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAsMutation, { data, loading, error }] = useRegisterAsMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      clinic: // value for 'clinic'
 *      medicalWorker: // value for 'medicalWorker'
 *      manufacturingCompany: // value for 'manufacturingCompany'
 *   },
 * });
 */
export function useRegisterAsMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.RegisterAsMutation, Types.RegisterAsMutationVariables>
) {
  return Apollo.useMutation<Types.RegisterAsMutation, Types.RegisterAsMutationVariables>(
    Operations.RegisterAs,
    baseOptions
  )
}
export type RegisterAsMutationHookResult = ReturnType<typeof useRegisterAsMutation>
export type RegisterAsMutationResult = Apollo.MutationResult<Types.RegisterAsMutation>
export type RegisterAsMutationOptions = Apollo.BaseMutationOptions<
  Types.RegisterAsMutation,
  Types.RegisterAsMutationVariables
>
export type ConfirmRegistrationMutationFn = Apollo.MutationFunction<
  Types.ConfirmRegistrationMutation,
  Types.ConfirmRegistrationMutationVariables
>

/**
 * __useConfirmRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRegistrationMutation, { data, loading, error }] = useConfirmRegistrationMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ConfirmRegistrationMutation,
    Types.ConfirmRegistrationMutationVariables
  >
) {
  return Apollo.useMutation<Types.ConfirmRegistrationMutation, Types.ConfirmRegistrationMutationVariables>(
    Operations.ConfirmRegistration,
    baseOptions
  )
}
export type ConfirmRegistrationMutationHookResult = ReturnType<typeof useConfirmRegistrationMutation>
export type ConfirmRegistrationMutationResult = Apollo.MutationResult<Types.ConfirmRegistrationMutation>
export type ConfirmRegistrationMutationOptions = Apollo.BaseMutationOptions<
  Types.ConfirmRegistrationMutation,
  Types.ConfirmRegistrationMutationVariables
>
