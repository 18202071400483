export const ArrayUtil = {
  last: function <T>(array: Array<T>) {
    return array[array.length - 1]
  },
  addIfExists: function <T>(arr: T[], val: T | undefined) {
    if (val === undefined) {
      return
    }

    arr.push(val)
  },
  pushMany: function <T>(arr: T[], vals: T[]) {
    vals.forEach(a => arr.push(a))
  },
  groupBy: function <TKey, TItem>(array: TItem[], getKey: (item: TItem) => TKey) {
    const map = new Map<TKey, TItem[]>()
    return array.reduce(function (accum, item) {
      const key = getKey(item)
      let keeper = map.get(key)

      if (!keeper) {
        keeper = []
        map.set(key, keeper)
        accum.push({ key, value: keeper })
      }

      keeper.push(item)

      return accum
    }, new Array<{ key: TKey; value: TItem[] }>())
  },
  toMap: function <TKey, TItem>(array: TItem[], getKey: (item: TItem) => TKey) {
    const map = new Map<TKey, TItem>()

    for (const item of array) {
      const key = getKey(item)
      map.set(key, item)
    }

    return map
  },
}
