import { useMemo } from 'react'
import { ValidationResult } from '../validation/ValidationResult'

export const useErrors = (...results: (ValidationResult | undefined)[]) => {
  const hasError = useMemo(() => !!results.find(a => a?.hasError), [results])

  const messages = useMemo(
    () =>
      results.reduce((accum, val) => {
        if (val) {
          accum.push(...val.messages)
        }
        return accum
      }, new Array<string>()),
    [results]
  )

  return {
    hasError,
    messages,
  }
}
