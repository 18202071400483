import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DocumentFileRef: any
  /** File upload scalar type */
  Upload: any
  UploadOrPath: any
}

export type Address = {
  __typename?: 'Address'
  id: Scalars['Int']
  address: Scalars['String']
  coords: GeoPoint
}

export type AllManufacturingCompanyInput = {
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  registrationNumber?: Maybe<Scalars['String']>
  businessCategory?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type AllMedicalWorkersInput = {
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  order?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type BaseClassification = {
  __typename?: 'BaseClassification'
  id: Scalars['Int']
  key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  generalImage?: Maybe<Scalars['String']>
  octagonImage?: Maybe<Scalars['String']>
}

export type ClassificationPayload = {
  __typename?: 'ClassificationPayload'
  id: Scalars['Int']
  key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  generalImage?: Maybe<Scalars['String']>
  octagonImage?: Maybe<Scalars['String']>
  parentId?: Maybe<Scalars['Int']>
  parent?: Maybe<BaseClassification>
  children?: Maybe<Array<Maybe<BaseClassification>>>
  services?: Maybe<Array<Maybe<MedicalService>>>
}

export type ClassificationQuery = {
  __typename?: 'ClassificationQuery'
  id: Scalars['Int']
  key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  generalImage?: Maybe<Scalars['String']>
  octagonImage?: Maybe<Scalars['String']>
  children?: Maybe<Array<Maybe<BaseClassification>>>
  medicalServices?: Maybe<Array<Maybe<MedicalService>>>
}

export type ClassificationsQuery = {
  __typename?: 'ClassificationsQuery'
  id: Scalars['Int']
  parentId?: Maybe<Scalars['Int']>
  key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  generalImage?: Maybe<Scalars['String']>
  octagonImage?: Maybe<Scalars['String']>
}

export type Clinic = {
  __typename?: 'Clinic'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
}

export type ClinicCard = {
  __typename?: 'ClinicCard'
  id: Scalars['Int']
  name: Scalars['String']
  isVerified: Scalars['Boolean']
  image: Scalars['String']
  address: Scalars['String']
  category: Scalars['String']
  rating: Scalars['Float']
  contacts: Array<ContactInfo>
}

export type ClinicFilteringFieldsInput = {
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
}

export type ClinicFloat = {
  __typename?: 'ClinicFloat'
  id: Scalars['Int']
  name: Scalars['String']
  address: Scalars['String']
  coords: GeoPoint
  image: Scalars['String']
  category: Scalars['String']
  isVerified: Scalars['Boolean']
  rating: Scalars['Float']
  info: Scalars['String']
  reviewsCount: Scalars['Int']
  specialists: Array<SpecialistCard>
  specialties: Array<Specialty>
  contacts: Array<ContactInfo>
  site: Scalars['String']
  type: Scalars['String']
  clinics?: Maybe<Array<Maybe<ClinicOtherLocation>>>
}

export type ClinicLocation = {
  __typename?: 'ClinicLocation'
  id: Scalars['Int']
  category?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  location: Location
}

export enum ClinicLocationCategory {
  Asl = 'ASL',
  Df = 'DF',
  Emcf = 'EMCF',
  Eyecf = 'EYECF',
  Hlthf = 'HLTHF',
  Hospital = 'HOSPITAL',
  Medf = 'MEDF',
  Medstr = 'MEDSTR',
  Natmc = 'NATMC',
  Prcc = 'PRCC',
  Psf = 'PSF',
  Surf = 'SURF',
}

export type ClinicLocationInput = {
  category: ClinicLocationCategory
  type: ClinicLocationType
  info?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  location: Scalars['String']
}

export enum ClinicLocationType {
  AslAsl = 'ASL_ASL',
  AslHmc = 'ASL_HMC',
  AslIndrl = 'ASL_INDRL',
  DfDentrc = 'DF_DENTRC',
  DfDentspc = 'DF_DENTSPC',
  DfDentc = 'DF_DENTC',
  EmcfPsyem = 'EMCF_PSYEM',
  EmcfUrgc = 'EMCF_URGC',
  EmcfEmgr = 'EMCF_EMGR',
  EmcfToxc = 'EMCF_TOXC',
  EmcfBrncc = 'EMCF_BRNCC',
  EmcfPdemr = 'EMCF_PDEMR',
  EmcfTrmc = 'EMCF_TRMC',
  EyecfOtpm = 'EYECF_OTPM',
  EyecfEyesc = 'EYECF_EYESC',
  EyecfOphtc = 'EYECF_OPHTC',
  HlthfCsmtc = 'HLTHF_CSMTC',
  HlthfGym = 'HLTHF_GYM',
  HlthfHlthrp = 'HLTHF_HLTHRP',
  HlthfHtspr = 'HLTHF_HTSPR',
  HlthfMdttc = 'HLTHF_MDTTC',
  HlthfMdbtr = 'HLTHF_MDBTR',
  HlthfPilst = 'HLTHF_PILST',
  HlthfPool = 'HLTHF_POOL',
  HlthfYgst = 'HLTHF_YGST',
  HlthfHlthcg = 'HLTHF_HLTHCG',
  HlthfBtcmp = 'HLTHF_BTCMP',
  HospitalUnvh = 'HOSPITAL_UNVH',
  HospitalPedh = 'HOSPITAL_PEDH',
  HospitalHsptl = 'HOSPITAL_HSPTL',
  MedfHspc = 'MEDF_HSPC',
  MedfRadc = 'MEDF_RADC',
  MedfLabbd = 'MEDF_LABBD',
  MedfRehbc = 'MEDF_REHBC',
  MedfInfsc = 'MEDF_INFSC',
  MedfChldps = 'MEDF_CHLDPS',
  MedfTrvlvc = 'MEDF_TRVLVC',
  MedstrDispnc = 'MEDSTR_DISPNC',
  MedstrNatphrm = 'MEDSTR_NATPHRM',
  MedstrPhrm = 'MEDSTR_PHRM',
  MedstrMedsstr = 'MEDSTR_MEDSSTR',
  NatmcChrcl = 'NATMC_CHRCL',
  NatmcAcpc = 'NATMC_ACPC',
  NatmcMassc = 'NATMC_MASSC',
  NatmcHolcl = 'NATMC_HOLCL',
  PrccSthlthc = 'PRCC_STHLTHC',
  PrccPedpcc = 'PRCC_PEDPCC',
  PrccCommcl = 'PRCC_COMMCL',
  PrccAdlpcc = 'PRCC_ADLPCC',
  PrccGrpcc = 'PRCC_GRPCC',
  PrccFampc = 'PRCC_FAMPC',
  PsfPsyhs = 'PSF_PSYHS',
  PsfPsycl = 'PSF_PSYCL',
  PsfPrmfc = 'PSF_PRMFC',
  PsfBhbtcmp = 'PSF_BHBTCMP',
  SurfFamplc = 'SURF_FAMPLC',
  SurfAllimc = 'SURF_ALLIMC',
  SurfHrngc = 'SURF_HRNGC',
  SurfDlkc = 'SURF_DLKC',
  SurfDerc = 'SURF_DERC',
  SurfOncc = 'SURF_ONCC',
  SurfGastrc = 'SURF_GASTRC',
  SurfCardc = 'SURF_CARDC',
  SurfOgync = 'SURF_OGYNC',
  SurfNrlc = 'SURF_NRLC',
  SurfEntc = 'SURF_ENTC',
  SurfPedmlcl = 'SURF_PEDMLCL',
  SurfMlspcl = 'SURF_MLSPCL',
  SurfErdc = 'SURF_ERDC',
  SurfAdtct = 'SURF_ADTCT',
  SurfAbscc = 'SURF_ABSCC',
  SurfPnmngc = 'SURF_PNMNGC',
  SurfSurcl = 'SURF_SURCL',
  SurfSuroutc = 'SURF_SUROUTC',
  SurfMedcsmc = 'SURF_MEDCSMC',
  SurfPlmc = 'SURF_PLMC',
  SurfPdtc = 'SURF_PDTC',
  SurfEndcc = 'SURF_ENDCC',
  SurfLvrc = 'SURF_LVRC',
  SurfInfdc = 'SURF_INFDC',
  SurfRhacl = 'SURF_RHACL',
}

export type ClinicOtherLocation = {
  __typename?: 'ClinicOtherLocation'
  id: Scalars['Int']
  type: Scalars['String']
  address: Scalars['String']
}

export type ClinicPaginationInput = {
  fields?: Maybe<ClinicFilteringFieldsInput>
  ids?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
}

export type ClinicPayload = {
  __typename?: 'ClinicPayload'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
}

export type ClinicProfileInput = {
  clinicName: Scalars['String']
  clinicLocation: ClinicLocationInput
}

export type ClinicsResults = {
  __typename?: 'ClinicsResults'
  total: Scalars['Int']
  items: Array<ClinicCard>
}

export type ConfirmRegistrationInput = {
  password: Scalars['String']
  token: Scalars['String']
}

export type ContactInfo = {
  __typename?: 'ContactInfo'
  id: Scalars['Int']
  personalPhone?: Maybe<Scalars['String']>
  workPhone?: Maybe<Scalars['String']>
  personalEmail?: Maybe<Scalars['String']>
  workEmail?: Maybe<Scalars['String']>
  preferredChannelCall?: Maybe<Scalars['Boolean']>
  preferredChannelText?: Maybe<Scalars['Boolean']>
  preferredChannelEmail?: Maybe<Scalars['Boolean']>
}

export type ContactInfoInput = {
  id?: Maybe<Scalars['Int']>
  personalEmail: Scalars['String']
  workEmail?: Maybe<Scalars['String']>
  personalPhone?: Maybe<Scalars['String']>
  workPhone?: Maybe<Scalars['String']>
  preferredChannelCall?: Maybe<Scalars['Boolean']>
  preferredChannelText?: Maybe<Scalars['Boolean']>
  preferredChannelEmail?: Maybe<Scalars['Boolean']>
}

export type CreateAndInviteMedicalWorkerInput = {
  profile: UserProfileParamsInput
  professionDesignations: Array<ProfessionDesignationInput>
}

export type CreateClassificationInput = {
  parentId?: Maybe<Scalars['Int']>
  services?: Maybe<Array<Scalars['Int']>>
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  generalImage?: Maybe<Scalars['UploadOrPath']>
  octagonImage?: Maybe<Scalars['UploadOrPath']>
}

export type CreateClinicInput = {
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
}

export type CreateClinicLocationInput = {
  clinicId: Scalars['Int']
  clinicLocation: CreateClinicLocationParamsInput
}

export type CreateClinicLocationParamsInput = {
  category: ClinicLocationCategory
  type: ClinicLocationType
  name: Scalars['String']
  info?: Maybe<Scalars['String']>
  location?: Maybe<LocationInput>
  contacts?: Maybe<ContactInfoInput>
}

export type CreateClinicProfileInput = {
  personalEmail: Scalars['String']
  workEmail?: Maybe<Scalars['String']>
  personalPhone?: Maybe<Scalars['String']>
  workPhone?: Maybe<Scalars['String']>
  clinicName: Scalars['String']
  clinicLocation: ClinicLocationInput
}

export type CreateDistributorInput = {
  name: Scalars['String']
  profileId?: Maybe<Scalars['Int']>
}

export type CreateDistributorLocationInput = {
  distributorId: Scalars['Int']
  name: Scalars['String']
  type: Scalars['String']
  info?: Maybe<Scalars['String']>
  specialty?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
}

export type CreateDistributorProfileInput = {
  type?: Maybe<DistributorAddressType>
  name: Scalars['String']
  address: Scalars['String']
}

export type CreateInsuranceInput = {
  type: EInsuranceType
  description: Scalars['String']
}

export type CreateLanguageInput = {
  code: Scalars['String']
  name: Scalars['String']
}

export type CreateLocationInput = {
  countryCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  address: Scalars['String']
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type CreateManufacturingCompanyProfileInput = {
  name: Scalars['String']
  category?: Maybe<Scalars['String']>
  email: Scalars['String']
  phone?: Maybe<Scalars['String']>
}

export type CreateMedicalServiceInput = {
  key: Scalars['String']
  name: Scalars['String']
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  tagIds: Array<Scalars['Int']>
  specialtyIds: Array<Scalars['Int']>
}

export type CreateMedicalSpecialtiesInput = {
  code?: Maybe<Scalars['String']>
  name: MedicalSpecialtyName
  type: MedicalSpecialtyType
  profession: Scalars['String']
}

export type CreateMedicalWorkerProfileInput = {
  personalEmail: Scalars['String']
  workEmail?: Maybe<Scalars['String']>
  personalPhone?: Maybe<Scalars['String']>
  workPhone?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  professionDesignations: Array<MedicalWorkerProfessionDesignationInput>
  licenseNumber?: Maybe<Scalars['String']>
}

export type CreateMedicalWorkerServiceDetailsInput = {
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['Upload']>
  isActive?: Maybe<Scalars['Boolean']>
  tags?: Maybe<Array<Scalars['String']>>
}

export type CreateMedicalWorkerServiceInput = {
  medicalServiceId: Scalars['Int']
  medicalService: CreateMedicalWorkerServiceDetailsInput
}

export type CreateProfessionDesignationInput = {
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type CreateSeoInput = {
  url: Scalars['String']
  title: Scalars['String']
  entity?: Maybe<Scalars['String']>
  entityId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  keywords?: Maybe<Scalars['String']>
  additionalParams?: Maybe<Scalars['String']>
}

export type CreateTagInput = {
  category: TagCategory
  name: Scalars['String']
}

export type CreateUserProfileInput = {
  clinic?: Maybe<CreateClinicProfileInput>
  medicalWorker?: Maybe<CreateMedicalWorkerProfileInput>
  distributor?: Maybe<CreateDistributorProfileInput>
  manufacturingCompany?: Maybe<CreateManufacturingCompanyProfileInput>
}

export type CreateUserRoleInput = {
  displayName: Scalars['String']
  description: Scalars['String']
  recordName: Scalars['String']
  scopeIds: Array<Scalars['Int']>
}

export type CurrentClinic = {
  __typename?: 'CurrentClinic'
  id: Scalars['Int']
  name: Scalars['String']
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  insurances?: Maybe<Array<Scalars['Int']>>
  documents?: Maybe<Array<Document>>
}

export type CurrentManufacturingCompany = {
  __typename?: 'CurrentManufacturingCompany'
  id: Scalars['Int']
  profile?: Maybe<UserProfile>
  contactInfo?: Maybe<Array<Maybe<ManufacturingCompanyContactInfo>>>
  registrationNumber?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  businessCategory?: Maybe<Scalars['String']>
}

export type CurrentMedicalWorker = {
  __typename?: 'CurrentMedicalWorker'
  id: Scalars['Int']
  npi?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  isVerified?: Maybe<Scalars['Boolean']>
  contactInfo?: Maybe<ContactInfo>
  professionDesignations: Array<MedicalWorkerProfessionDesignation>
  clinicLocations?: Maybe<Array<Maybe<ClinicLocation>>>
  documents?: Maybe<Array<Maybe<Document>>>
  profile?: Maybe<UserProfile>
}

export type CurrentUser = {
  __typename?: 'CurrentUser'
  id: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  roles: Array<Scalars['String']>
}

export type DeleteDocumentInput = {
  id: Scalars['Int']
  referrerType: DocumentReferrerType
  referrerId?: Maybe<Scalars['Int']>
}

export type Distributor = {
  __typename?: 'Distributor'
  id: Scalars['Int']
  name: Scalars['String']
  profile?: Maybe<UserProfile>
  distributorLocation?: Maybe<DistributorLocation>
}

export enum DistributorAddressType {
  Default = 'DEFAULT',
}

export type DistributorFilteringFieldsInput = {
  name?: Maybe<Scalars['String']>
}

export type DistributorLocation = {
  __typename?: 'DistributorLocation'
  id: Scalars['Int']
  type?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  specialty?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
  specialtyServed?: Maybe<Scalars['String']>
}

export type DistributorLocationPayload = {
  __typename?: 'DistributorLocationPayload'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  specialty?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
}

export type DistributorPaginationInput = {
  fields?: Maybe<DistributorFilteringFieldsInput>
  ids?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  perPage?: Maybe<Scalars['Int']>
}

export type DistributorPayload = {
  __typename?: 'DistributorPayload'
  id: Scalars['Int']
  name: Scalars['String']
  profile?: Maybe<UserProfile>
  distributorLocation?: Maybe<DistributorLocation>
}

export type DistributorProfileInput = {
  type?: Maybe<DistributorAddressType>
  name: Scalars['String']
  address: Scalars['String']
}

export type Document = {
  __typename?: 'Document'
  id: Scalars['Int']
  name: Scalars['String']
  fileRef: Scalars['String']
  type: Scalars['String']
}

export enum DocumentReferrerType {
  MedicalWorker = 'MEDICAL_WORKER',
  Clinic = 'CLINIC',
  Product = 'PRODUCT',
  DistributorLocation = 'DISTRIBUTOR_LOCATION',
}

export enum DocumentType {
  Certificate = 'CERTIFICATE',
  Award = 'AWARD',
  Publication = 'PUBLICATION',
  TechnicalSpecification = 'TECHNICAL_SPECIFICATION',
  ProductDocumentation = 'PRODUCT_DOCUMENTATION',
  PolicyAndForms = 'POLICY_AND_FORMS',
}

export enum EInsuranceType {
  Health = 'HEALTH',
  Dental = 'DENTAL',
  Vision = 'VISION',
  Other = 'OTHER',
}

export type FavoriteCardInput = {
  type?: Maybe<FavoriteType>
  id: Scalars['Int']
}

export type FavoriteCards = {
  __typename?: 'FavoriteCards'
  services: Array<MedicalWorkerServicePayload>
  medicalWorkers: Array<MedicalWorker>
  clinicLocations: Array<ClinicLocation>
}

export enum FavoriteType {
  MedicalWorker = 'MEDICAL_WORKER',
  MedicalWorkerService = 'MEDICAL_WORKER_SERVICE',
  ClinicLocation = 'CLINIC_LOCATION',
  ClinicLocationProduct = 'CLINIC_LOCATION_PRODUCT',
}

export type FilterLanguageInput = {
  id?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type FilterSearchInput = {
  classification?: Maybe<Scalars['Int']>
  gender?: Maybe<Gender>
  type: SearchType
}

export type ForgotPasswordInput = {
  email: Scalars['String']
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
}

export type GeoPoint = {
  __typename?: 'GeoPoint'
  longitude: Scalars['Float']
  latitude: Scalars['Float']
}

export type Insurance = {
  __typename?: 'Insurance'
  id: Scalars['Int']
  type: EInsuranceType
  description: Scalars['String']
}

export type InsuranceFilter = {
  id?: Maybe<Scalars['Int']>
  type?: Maybe<EInsuranceType>
  description?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type InsurancesList = {
  __typename?: 'InsurancesList'
  items: Array<Insurance>
  total: Scalars['Int']
}

export type InvitationContactDetails = {
  __typename?: 'InvitationContactDetails'
  id: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type InviteMedicalWorkersInput = {
  clinicLocationId: Scalars['Int']
  medicalWorkerIds?: Maybe<Array<Scalars['Int']>>
  newMedicalWorker?: Maybe<CreateAndInviteMedicalWorkerInput>
}

export type InviteMedicalWorkersPayload = {
  __typename?: 'InviteMedicalWorkersPayload'
  sentCount: Scalars['Int']
  sentFails?: Maybe<Array<Maybe<InvitationContactDetails>>>
}

export type KeyTitleItem = {
  __typename?: 'KeyTitleItem'
  key: Scalars['String']
  title: Scalars['String']
}

export type LanguagePayload = {
  __typename?: 'LanguagePayload'
  id: Scalars['Int']
  code: Scalars['String']
  name: Scalars['String']
}

export type LanguagesInput = {
  id: Scalars['Int']
}

export type ListLanguagePayload = {
  __typename?: 'ListLanguagePayload'
  items: Array<LanguagePayload>
  total: Scalars['Int']
}

export type ListProfessionDesignationsInput = {
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type ListProfessionDesignationsPayload = {
  __typename?: 'ListProfessionDesignationsPayload'
  total?: Maybe<Scalars['Int']>
  items?: Maybe<Array<ProfessionDesignation>>
}

export type Location = {
  __typename?: 'Location'
  id: Scalars['Int']
  countryCode: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
  zip?: Maybe<Scalars['String']>
  address: Scalars['String']
  coords: Array<Scalars['Float']>
}

export type LocationInput = {
  countryCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zip: Scalars['String']
  address: Scalars['String']
}

export type LocationPayload = {
  __typename?: 'LocationPayload'
  id: Scalars['Int']
  countryCode: Scalars['String']
  city: Scalars['String']
  state: Scalars['String']
  zip?: Maybe<Scalars['String']>
  address: Scalars['String']
  coords: Array<Scalars['Float']>
}

export type LocationSearchInput = {
  longitude: Scalars['Float']
  latitude: Scalars['Float']
  distanceKm?: Maybe<Scalars['Int']>
}

export type LoginInput = {
  email: Scalars['String']
  password: Scalars['String']
  tfaToken?: Maybe<Scalars['String']>
}

export type LoginPayload = {
  __typename?: 'LoginPayload'
  accessToken?: Maybe<Scalars['String']>
  expiresAt?: Maybe<Scalars['Date']>
  refreshToken?: Maybe<Scalars['String']>
  requiresTFA?: Maybe<Scalars['Boolean']>
}

export type ManufacturingCompany = {
  __typename?: 'ManufacturingCompany'
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
}

export type ManufacturingCompanyContactInfo = {
  __typename?: 'ManufacturingCompanyContactInfo'
  id: Scalars['Int']
  personalPhone?: Maybe<Scalars['String']>
  workPhone?: Maybe<Scalars['String']>
  personalEmail?: Maybe<Scalars['String']>
  workEmail?: Maybe<Scalars['String']>
  preferredChannelCall?: Maybe<Scalars['Boolean']>
  preferredChannelText?: Maybe<Scalars['Boolean']>
  preferredChannelEmail?: Maybe<Scalars['Boolean']>
  ownerType?: Maybe<Scalars['String']>
}

export type ManufacturingCompanyContactInfoInput = {
  id?: Maybe<Scalars['Int']>
  personalPhone?: Maybe<Scalars['String']>
  workPhone?: Maybe<Scalars['String']>
  personalEmail?: Maybe<Scalars['String']>
  workEmail?: Maybe<Scalars['String']>
  preferredChannelCall?: Maybe<Scalars['Boolean']>
  preferredChannelText?: Maybe<Scalars['Boolean']>
  preferredChannelEmail?: Maybe<Scalars['Boolean']>
  ownerType?: Maybe<Scalars['String']>
}

export type ManufacturingCompanyInput = {
  name: Scalars['String']
  category: Scalars['String']
}

export type MedicalService = {
  __typename?: 'MedicalService'
  id: Scalars['Int']
  name: Scalars['String']
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  specialties?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type MedicalServicePayload = {
  __typename?: 'MedicalServicePayload'
  id: Scalars['Int']
  name: Scalars['String']
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  specialties?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum MedicalSpecialtyName {
  AdolescentMedicine = 'ADOLESCENT_MEDICINE',
  AllergyAndAsthma = 'ALLERGY_AND_ASTHMA',
  Anesthesiology = 'ANESTHESIOLOGY',
  Cardiology = 'CARDIOLOGY',
  ColonAndRectalSurgery = 'COLON_AND_RECTAL_SURGERY',
  Dentistry = 'DENTISTRY',
  Dermatology = 'DERMATOLOGY',
  EarNoseAndThroatEnt = 'EAR_NOSE_AND_THROAT_ENT',
  EmergencyMedicine = 'EMERGENCY_MEDICINE',
  Endocrinology = 'ENDOCRINOLOGY',
  FamilyPractice = 'FAMILY_PRACTICE',
  FunctionalMedicine = 'FUNCTIONAL_MEDICINE',
  Gastroenterology = 'GASTROENTEROLOGY',
  GeneralSurgery = 'GENERAL_SURGERY',
  Genetics = 'GENETICS',
  Geriatrics = 'GERIATRICS',
  Hematology = 'HEMATOLOGY',
  Hepatology = 'HEPATOLOGY',
  HolisticMedicine = 'HOLISTIC_MEDICINE',
  Homecare = 'HOMECARE',
  Hospitalist = 'HOSPITALIST',
  Immunology = 'IMMUNOLOGY',
  InfectiousDisease = 'INFECTIOUS_DISEASE',
  InternalMedicine = 'INTERNAL_MEDICINE',
  InterventionalCardiology = 'INTERVENTIONAL_CARDIOLOGY',
  MaxillofacialSurgery = 'MAXILLOFACIAL_SURGERY',
  Nephrology = 'NEPHROLOGY',
  Neurology = 'NEUROLOGY',
  Neurosurgery = 'NEUROSURGERY',
  Nutrition = 'NUTRITION',
  ObstetricsAndGynecology = 'OBSTETRICS_AND_GYNECOLOGY',
  Oncology = 'ONCOLOGY',
  Ophthalmology = 'OPHTHALMOLOGY',
  OrthopedicSurgery = 'ORTHOPEDIC_SURGERY',
  Pathology = 'PATHOLOGY',
  Pediatrics = 'PEDIATRICS',
  PediatricSurgery = 'PEDIATRIC_SURGERY',
  PediatricUrology = 'PEDIATRIC_UROLOGY',
  PlasticSurgery = 'PLASTIC_SURGERY',
  Podiatry = 'PODIATRY',
  Psychiatry = 'PSYCHIATRY',
  Pulmonology = 'PULMONOLOGY',
  Radiology = 'RADIOLOGY',
  Rehabilitation = 'REHABILITATION',
  Rheumatology = 'RHEUMATOLOGY',
  SportMedicine = 'SPORT_MEDICINE',
  ThoracicSurgery = 'THORACIC_SURGERY',
  Toxicology = 'TOXICOLOGY',
  Urology = 'UROLOGY',
  VascularSurgery = 'VASCULAR_SURGERY',
}

export enum MedicalSpecialtyType {
  MedicalSpecialist = 'MEDICAL_SPECIALIST',
  NaturalMedicine = 'NATURAL_MEDICINE',
  PrimaryCare = 'PRIMARY_CARE',
  Surgery = 'SURGERY',
  WomensHealth = 'WOMENS_HEALTH',
}

export type MedicalWorker = {
  __typename?: 'MedicalWorker'
  id: Scalars['Int']
  name: Scalars['String']
  npi?: Maybe<Scalars['String']>
  email: Scalars['String']
  licenseNumber?: Maybe<Scalars['String']>
  isVerified?: Maybe<Scalars['Boolean']>
}

export type MedicalWorkerProfessionDesignation = {
  __typename?: 'MedicalWorkerProfessionDesignation'
  id: Scalars['Int']
  position: Scalars['Int']
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type MedicalWorkerProfessionDesignationInput = {
  id: Scalars['Int']
  position: Scalars['Int']
}

export type MedicalWorkerProfileInput = {
  professionDesignations: Array<MedicalWorkerProfessionDesignationInput>
}

export type MedicalWorkerServicePayload = {
  __typename?: 'MedicalWorkerServicePayload'
  id: Scalars['Int']
  name: Scalars['String']
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  constTags?: Maybe<Array<Maybe<Scalars['String']>>>
  editableTags?: Maybe<Array<Maybe<Scalars['String']>>>
  specialties?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Register new user */
  registerAs?: Maybe<Scalars['Boolean']>
  /** Confirm user registration */
  confirmRegistration?: Maybe<Scalars['Boolean']>
  /** Login user */
  login?: Maybe<LoginPayload>
  /** Logout user */
  logout?: Maybe<Scalars['Boolean']>
  /** Refresh access token */
  refreshToken?: Maybe<RefreshTokenPayload>
  /** Trigger password reset action */
  forgotPassword?: Maybe<Scalars['Boolean']>
  /** Verify Two-Factor authentication */
  verifyTFA?: Maybe<VerifyTfaPayload>
  /** Disable Two-Factor authentication */
  disableTFA?: Maybe<Scalars['Boolean']>
  /** Regenerate Two-Factor authentication recovery codes */
  regenerateTFA?: Maybe<RegenerateTfaPayload>
  /** Create new classification */
  createClassification?: Maybe<ClassificationPayload>
  /** Update classification */
  updateClassification?: Maybe<ClassificationPayload>
  /** Delete classification */
  deleteClassification?: Maybe<Scalars['Boolean']>
  /** Create new clinic location */
  createClinicLocation?: Maybe<Scalars['Int']>
  /** Update clinic location */
  updateClinicLocation?: Maybe<Scalars['Boolean']>
  /** Delete clinic location */
  deleteClinicLocation?: Maybe<Scalars['Boolean']>
  /** Detach medical worker from clinic location */
  detachMedicalWorkerFromClinicLocation?: Maybe<Scalars['Boolean']>
  /** Create clinic */
  createClinic?: Maybe<ClinicPayload>
  /** Update clinic */
  updateClinic?: Maybe<ClinicPayload>
  /** Delete clinic */
  deleteClinic?: Maybe<Scalars['Boolean']>
  /** Create distributor location */
  createDistributorLocation?: Maybe<DistributorLocationPayload>
  /** Update distributor location */
  updateDistributorLocation?: Maybe<DistributorLocationPayload>
  /** Create Distributor */
  createDistributor?: Maybe<DistributorPayload>
  /** Update Distributor */
  updateDistributor?: Maybe<DistributorPayload>
  /** Delete Distributor */
  deleteDistributor?: Maybe<Scalars['Boolean']>
  /** Upload new file document */
  uploadDocument?: Maybe<Scalars['Boolean']>
  /** Delete file document */
  deleteDocument?: Maybe<Scalars['Boolean']>
  addFavoriteCard?: Maybe<Scalars['Boolean']>
  removeFavoriteCard?: Maybe<Scalars['Boolean']>
  /** Create new Insurance */
  createInsurance?: Maybe<Insurance>
  /** Update Insurance by id */
  updateInsurance?: Maybe<Insurance>
  /** Delete Insurance by id */
  deleteInsurance?: Maybe<Scalars['Boolean']>
  /** Accept medical worker accession */
  acceptMedicalWorkerRequestToJoinClinicLocation?: Maybe<Scalars['Boolean']>
  /** Reject medical worker accession */
  rejectMedicalWorkerRequestToJoinClinicLocation?: Maybe<Scalars['Boolean']>
  /** Send an invitation request to medical workers to join the clinic location */
  inviteMedicalWorkers?: Maybe<InviteMedicalWorkersPayload>
  /** Accept clinic location invitation */
  acceptClinicLocationInvitation?: Maybe<Scalars['Boolean']>
  /** Reject clinic location invitation */
  rejectClinicLocationInvitation?: Maybe<Scalars['Boolean']>
  /** Send an invitation request to join the clinic location */
  joinClinicLocation?: Maybe<Scalars['Boolean']>
  /** Create new Language */
  createLanguage: LanguagePayload
  /** Update Language */
  updateLanguage: LanguagePayload
  /** Update Language */
  deleteLanguage: Scalars['Boolean']
  /** Create new location */
  createLocation?: Maybe<LocationPayload>
  /** Update location */
  updateLocation?: Maybe<LocationPayload>
  /** Delete location */
  deleteLocation?: Maybe<Scalars['Boolean']>
  /** Create new medical service */
  createMedicalService?: Maybe<MedicalServicePayload>
  /** Update medical service */
  updateMedicalService?: Maybe<MedicalServicePayload>
  /** Delete medical service */
  deleteMedicalService?: Maybe<Scalars['Boolean']>
  /** Create new medical worker's service */
  createMedicalWorkerService?: Maybe<MedicalWorkerServicePayload>
  /** Update medical worker's service */
  updateMedicalWorkerService?: Maybe<MedicalWorkerServicePayload>
  /** Delete medical worker's service */
  deleteMedicalWorkerService?: Maybe<Scalars['Boolean']>
  /** Create new medical specialty */
  createMedicalSpecialty?: Maybe<Scalars['Boolean']>
  /** Update medical specialty */
  updateMedicalSpecialty?: Maybe<Scalars['Boolean']>
  /** Delete medical specialty */
  deleteMedicalSpecialty?: Maybe<Scalars['Boolean']>
  /** Create new ProfessionDesignation */
  createProfessionDesignation?: Maybe<ProfessionDesignation>
  /** Update ProfessionDesignation by id */
  updateProfessionDesignation?: Maybe<ProfessionDesignation>
  /** Delete ProfessionDesignation by id */
  deleteProfessionDesignation?: Maybe<Scalars['Boolean']>
  /** Create new seo data */
  createSeo?: Maybe<Seo>
  /** Update existed seo data */
  updateSeo?: Maybe<Seo>
  /** delete seo data */
  deleteSeo?: Maybe<Scalars['Boolean']>
  /** Create new tag */
  createTag: TagPayload
  /** Update tag */
  updateTag: TagPayload
  /** Delete tag */
  deleteTag: Scalars['Boolean']
  /** Update user profile */
  updateUserProfile?: Maybe<Scalars['Boolean']>
  /** Update user password */
  updateUserPassword?: Maybe<Scalars['Boolean']>
  /** Update user email */
  updateUserEmail?: Maybe<Scalars['Boolean']>
  /** Confirm update user email */
  confirmUserEmailUpdate?: Maybe<Scalars['Boolean']>
  /** Create new user role */
  createUserRole?: Maybe<UserRole>
  /** Update user role */
  updateUserRole?: Maybe<UserRole>
  /** Delete user role */
  deleteUserRole?: Maybe<Scalars['Boolean']>
  /** Create accompanying profile */
  createAccompanyingProfile?: Maybe<Scalars['Boolean']>
  /** Update clinic profile */
  updateClinicProfile?: Maybe<Scalars['Boolean']>
  /** Delete clinic profile */
  deleteClinicProfile?: Maybe<Scalars['Boolean']>
  /** Update medical worker profile */
  updateMedicalWorkerProfile?: Maybe<Scalars['Boolean']>
  /** Update manufacturing company profile */
  updateManufacturingCompanyProfile?: Maybe<Scalars['Boolean']>
  /** Delete manufacturing company profile */
  deleteManufacturingCompanyProfile?: Maybe<Scalars['Boolean']>
  /** Delete medical worker profile */
  deleteMedicalWorkerProfile?: Maybe<Scalars['Boolean']>
}

export type MutationRegisterAsArgs = {
  input?: Maybe<RegisterUserInput>
}

export type MutationConfirmRegistrationArgs = {
  input?: Maybe<ConfirmRegistrationInput>
}

export type MutationLoginArgs = {
  input?: Maybe<LoginInput>
}

export type MutationRefreshTokenArgs = {
  input?: Maybe<RefreshTokenInput>
}

export type MutationForgotPasswordArgs = {
  input?: Maybe<ForgotPasswordInput>
}

export type MutationVerifyTfaArgs = {
  input?: Maybe<VerifyTfaInput>
}

export type MutationCreateClassificationArgs = {
  input?: Maybe<CreateClassificationInput>
}

export type MutationUpdateClassificationArgs = {
  input?: Maybe<UpdateClassificationInput>
}

export type MutationDeleteClassificationArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateClinicLocationArgs = {
  input?: Maybe<CreateClinicLocationInput>
}

export type MutationUpdateClinicLocationArgs = {
  input?: Maybe<UpdateClinicLocationInput>
}

export type MutationDeleteClinicLocationArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationDetachMedicalWorkerFromClinicLocationArgs = {
  clinicLocationId: Scalars['Int']
  medicalWorkerId: Scalars['Int']
}

export type MutationCreateClinicArgs = {
  input?: Maybe<CreateClinicInput>
}

export type MutationUpdateClinicArgs = {
  input?: Maybe<UpdateClinicInput>
}

export type MutationDeleteClinicArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateDistributorLocationArgs = {
  input?: Maybe<CreateDistributorLocationInput>
}

export type MutationUpdateDistributorLocationArgs = {
  input?: Maybe<UpdateDistributorLocationInput>
}

export type MutationCreateDistributorArgs = {
  input?: Maybe<CreateDistributorInput>
}

export type MutationUpdateDistributorArgs = {
  input?: Maybe<UpdateDistributorInput>
}

export type MutationDeleteDistributorArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationUploadDocumentArgs = {
  input?: Maybe<UploadDocumentInput>
}

export type MutationDeleteDocumentArgs = {
  input?: Maybe<DeleteDocumentInput>
}

export type MutationAddFavoriteCardArgs = {
  input?: Maybe<FavoriteCardInput>
}

export type MutationRemoveFavoriteCardArgs = {
  input?: Maybe<FavoriteCardInput>
}

export type MutationCreateInsuranceArgs = {
  input?: Maybe<CreateInsuranceInput>
}

export type MutationUpdateInsuranceArgs = {
  input?: Maybe<UpdateInsuranceInput>
}

export type MutationDeleteInsuranceArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationAcceptMedicalWorkerRequestToJoinClinicLocationArgs = {
  invitationId: Scalars['String']
}

export type MutationRejectMedicalWorkerRequestToJoinClinicLocationArgs = {
  invitationId: Scalars['String']
}

export type MutationInviteMedicalWorkersArgs = {
  input?: Maybe<InviteMedicalWorkersInput>
}

export type MutationAcceptClinicLocationInvitationArgs = {
  invitationId: Scalars['String']
}

export type MutationRejectClinicLocationInvitationArgs = {
  invitationId: Scalars['String']
}

export type MutationJoinClinicLocationArgs = {
  clinicLocationId: Scalars['Int']
}

export type MutationCreateLanguageArgs = {
  input?: Maybe<CreateLanguageInput>
}

export type MutationUpdateLanguageArgs = {
  input?: Maybe<UpdateLanguageInput>
}

export type MutationDeleteLanguageArgs = {
  id: Scalars['Int']
}

export type MutationCreateLocationArgs = {
  input?: Maybe<CreateLocationInput>
}

export type MutationUpdateLocationArgs = {
  input?: Maybe<UpdateLocationInput>
}

export type MutationDeleteLocationArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateMedicalServiceArgs = {
  input?: Maybe<CreateMedicalServiceInput>
}

export type MutationUpdateMedicalServiceArgs = {
  input?: Maybe<UpdateMedicalServiceInput>
}

export type MutationDeleteMedicalServiceArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateMedicalWorkerServiceArgs = {
  input?: Maybe<CreateMedicalWorkerServiceInput>
}

export type MutationUpdateMedicalWorkerServiceArgs = {
  input?: Maybe<UpdateMedicalWorkerServiceInput>
}

export type MutationDeleteMedicalWorkerServiceArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateMedicalSpecialtyArgs = {
  input?: Maybe<CreateMedicalSpecialtiesInput>
}

export type MutationUpdateMedicalSpecialtyArgs = {
  input?: Maybe<UpdateMedicalSpecialtiesInput>
}

export type MutationDeleteMedicalSpecialtyArgs = {
  id: Scalars['Int']
}

export type MutationCreateProfessionDesignationArgs = {
  input?: Maybe<CreateProfessionDesignationInput>
}

export type MutationUpdateProfessionDesignationArgs = {
  input?: Maybe<ProfessionDesignationInput>
}

export type MutationDeleteProfessionDesignationArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateSeoArgs = {
  input: CreateSeoInput
}

export type MutationUpdateSeoArgs = {
  input: UpdateSeoInput
}

export type MutationDeleteSeoArgs = {
  id: Scalars['String']
}

export type MutationCreateTagArgs = {
  input?: Maybe<CreateTagInput>
}

export type MutationUpdateTagArgs = {
  input?: Maybe<UpdateTagInput>
}

export type MutationDeleteTagArgs = {
  id: Scalars['Int']
}

export type MutationUpdateUserProfileArgs = {
  input?: Maybe<UpdateUserProfileInput>
}

export type MutationUpdateUserPasswordArgs = {
  input?: Maybe<UpdateUserPasswordInput>
}

export type MutationUpdateUserEmailArgs = {
  input?: Maybe<UpdateUserEmailInput>
}

export type MutationConfirmUserEmailUpdateArgs = {
  token: Scalars['String']
}

export type MutationCreateUserRoleArgs = {
  input?: Maybe<CreateUserRoleInput>
}

export type MutationUpdateUserRoleArgs = {
  input?: Maybe<UpdateUserRoleInput>
}

export type MutationDeleteUserRoleArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationCreateAccompanyingProfileArgs = {
  input?: Maybe<CreateUserProfileInput>
}

export type MutationUpdateClinicProfileArgs = {
  input?: Maybe<UpdateClinicProfileInput>
}

export type MutationDeleteClinicProfileArgs = {
  clinicId: Scalars['Int']
}

export type MutationUpdateMedicalWorkerProfileArgs = {
  input?: Maybe<UpdateMedicalWorkerProfileInput>
}

export type MutationUpdateManufacturingCompanyProfileArgs = {
  input?: Maybe<UpdateManufacturingCompanyProfileInput>
}

export type MutationDeleteManufacturingCompanyProfileArgs = {
  manufacturingCompanyId: Scalars['Int']
}

export type MutationDeleteMedicalWorkerProfileArgs = {
  medicalWorkerId: Scalars['Int']
}

export type ProfessionDesignation = {
  __typename?: 'ProfessionDesignation'
  id: Scalars['Int']
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type ProfessionDesignationInput = {
  id: Scalars['Int']
  position: Scalars['Int']
  title: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  /** Setup Two-Factor authentication */
  setupTFA?: Maybe<SetupTfaPayload>
  /** List of Clinic Location Categories */
  listClinicLocationCategories: Array<KeyTitleItem>
  /** List of Clinic Location Types */
  listClinicLocationType: Array<KeyTitleItem>
  /** Get a list of all classifications */
  classifications: Array<ClassificationsQuery>
  /** Get a classification */
  classification?: Maybe<ClassificationQuery>
  /** Get clinic record */
  clinic?: Maybe<Clinic>
  /** Get clinics records */
  clinics?: Maybe<Array<Maybe<Clinic>>>
  /** Get a list of distributors locations */
  listDistributorsLocations?: Maybe<Array<Maybe<DistributorLocation>>>
  /** Get distributor record */
  distributor?: Maybe<Distributor>
  /** Get distributor record of the current user */
  currentDistributor?: Maybe<Distributor>
  /** Get a list of distributors */
  listDistributors?: Maybe<Array<Maybe<Distributor>>>
  /** Get distributors records */
  distributors?: Maybe<Array<Maybe<Distributor>>>
  getFavoriteCards?: Maybe<FavoriteCards>
  /** Get list of insurances */
  insurances?: Maybe<InsurancesList>
  /** Get one insurance by id */
  insurance?: Maybe<Insurance>
  /** Get one language by id */
  language: LanguagePayload
  /** Get list languages */
  languages: ListLanguagePayload
  /** Get location record */
  location?: Maybe<Location>
  /** Get a list of locations */
  allLocations: Array<Location>
  /** Get medical service record */
  medicalService?: Maybe<MedicalService>
  /** Get a list of medical services */
  allMedicalServices: Array<MedicalService>
  /** Get list of ProfessionDesignations */
  listProfessionDesignations: ListProfessionDesignationsPayload
  /** Get one ProfessionDesignation by id */
  getProfessionDesignation?: Maybe<ProfessionDesignation>
  search: SearchResults
  /** Get list of existed seo data */
  listSeo?: Maybe<SeoList>
  /** Get one seo data from existed */
  getSeo?: Maybe<Seo>
  /** Get list of tags */
  getTags: TagsPayload
  /** Get currently logged in user */
  me?: Maybe<CurrentUser>
  userProfile?: Maybe<UserProfile>
  /** Get manufacturing company profile */
  manufacturingCompany?: Maybe<ManufacturingCompany>
  /** Get currently logged in user's clinic profile */
  currentClinicProfile?: Maybe<CurrentClinic>
  /** Get currently logged in user's clinic profile */
  currentMedicalWorkerProfile?: Maybe<CurrentMedicalWorker>
  /** Get currently logged in user's clinic profile */
  currentManufacturingCompanyProfile?: Maybe<CurrentManufacturingCompany>
  /** Get a list of user roles */
  allUserRoles: Array<UserRole>
  /** Get a list of role scopes */
  allRoleScopes: Array<RoleScope>
  /** Get a list of Medical Workers */
  allMedicalWorkers?: Maybe<Array<Maybe<MedicalWorker>>>
  /** Get a list of Manufacturing Companies */
  allManufacturingCompanies?: Maybe<Array<Maybe<ManufacturingCompany>>>
}

export type QueryClassificationArgs = {
  id: Scalars['Int']
}

export type QueryClinicArgs = {
  id: Scalars['Int']
}

export type QueryClinicsArgs = {
  filter?: Maybe<ClinicPaginationInput>
}

export type QueryDistributorArgs = {
  id: Scalars['Int']
}

export type QueryDistributorsArgs = {
  filter?: Maybe<DistributorPaginationInput>
}

export type QueryInsurancesArgs = {
  input?: Maybe<InsuranceFilter>
}

export type QueryInsuranceArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryLanguageArgs = {
  id: Scalars['Int']
}

export type QueryLanguagesArgs = {
  input: FilterLanguageInput
}

export type QueryLocationArgs = {
  id: Scalars['Int']
}

export type QueryMedicalServiceArgs = {
  id: Scalars['Int']
}

export type QueryListProfessionDesignationsArgs = {
  input?: Maybe<ListProfessionDesignationsInput>
}

export type QueryGetProfessionDesignationArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QuerySearchArgs = {
  input: SearchInput
}

export type QueryListSeoArgs = {
  input?: Maybe<SeoListInput>
}

export type QueryGetSeoArgs = {
  id: Scalars['String']
}

export type QueryGetTagsArgs = {
  name: Scalars['String']
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type QueryManufacturingCompanyArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryAllManufacturingCompaniesArgs = {
  input?: Maybe<AllManufacturingCompanyInput>
}

export type RefreshTokenInput = {
  token: Scalars['String']
}

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload'
  accessToken: Scalars['String']
  expiresAt: Scalars['Date']
  refreshToken: Scalars['String']
}

export type RegenerateTfaPayload = {
  __typename?: 'RegenerateTFAPayload'
  recoveryCodes: Array<Scalars['String']>
}

export type RegisterUserInput = {
  profile: UserProfileInput
  clinic?: Maybe<ClinicProfileInput>
  medicalWorker?: Maybe<MedicalWorkerProfileInput>
  distributor?: Maybe<DistributorProfileInput>
  manufacturingCompany?: Maybe<ManufacturingCompanyInput>
}

export type RoleScope = {
  __typename?: 'RoleScope'
  id: Scalars['Int']
  description?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
}

export type SearchInput = {
  text?: Maybe<Scalars['String']>
  location?: Maybe<LocationSearchInput>
  filter: FilterSearchInput
}

export type SearchResults = {
  __typename?: 'SearchResults'
  clinics?: Maybe<ClinicsResults>
  specialists?: Maybe<SpecialistsResults>
  treatments?: Maybe<TreatmentsResults>
}

export enum SearchType {
  All = 'ALL',
  Treatments = 'TREATMENTS',
  Specialists = 'SPECIALISTS',
  Clinics = 'CLINICS',
  Technologies = 'TECHNOLOGIES',
}

export type Seo = {
  __typename?: 'Seo'
  id: Scalars['String']
  url: Scalars['String']
  title: Scalars['String']
  entity?: Maybe<Scalars['String']>
  entityId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  keywords?: Maybe<Scalars['String']>
  additionalParams?: Maybe<Scalars['String']>
}

export type SeoList = {
  __typename?: 'SeoList'
  items: Array<Seo>
  total?: Maybe<Scalars['Int']>
}

export type SeoListInput = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type SetupTfaPayload = {
  __typename?: 'SetupTFAPayload'
  qrCodeDataUrl: Scalars['String']
  secretKey: Scalars['String']
}

export type SpecialistCard = {
  __typename?: 'SpecialistCard'
  id: Scalars['Int']
  name: Scalars['String']
  specialty: Scalars['String']
  image: Scalars['String']
  isVerified: Scalars['Boolean']
  rating: Scalars['Float']
  isExpert: Scalars['Boolean']
  contacts: ContactInfo
  description?: Maybe<Scalars['String']>
}

export type SpecialistFloat = {
  __typename?: 'SpecialistFloat'
  id: Scalars['Int']
  name: Scalars['String']
  specialty: Scalars['String']
  image: Scalars['String']
  isVerified: Scalars['Boolean']
  rating: Scalars['Float']
  isExpert: Scalars['Boolean']
  description: Scalars['String']
  addresses: Array<Address>
  treatments: Array<TreatmentCard>
  contacts: ContactInfo
  reviewsCount: Scalars['Int']
}

export type SpecialistsResults = {
  __typename?: 'SpecialistsResults'
  total: Scalars['Int']
  items: Array<SpecialistCard>
}

export type Specialty = {
  __typename?: 'Specialty'
  id: Scalars['Int']
  title: Scalars['String']
}

export enum TagCategory {
  ProductTag = 'PRODUCT_TAG',
  MedicalServiceTag = 'MEDICAL_SERVICE_TAG',
}

export type TagPayload = {
  __typename?: 'TagPayload'
  id: Scalars['Int']
  category: Scalars['String']
  name: Scalars['String']
}

export type TagsPayload = {
  __typename?: 'TagsPayload'
  tags: Array<TagPayload>
  total: Scalars['Int']
}

export type TreatmentCard = {
  __typename?: 'TreatmentCard'
  id: Scalars['Int']
  name: Scalars['String']
  image: Scalars['String']
  specialist: TreatmentSpecialist
  isExpert: Scalars['Boolean']
  rating: Scalars['Float']
  price: Scalars['Int']
  description?: Maybe<Scalars['String']>
}

export type TreatmentFloat = {
  __typename?: 'TreatmentFloat'
  id: Scalars['Int']
  name: Scalars['String']
  image: Scalars['String']
  specialist: TreatmentSpecialist
  isExpert: Scalars['Boolean']
  rating: Scalars['Float']
  price: Scalars['Int']
  description: Scalars['String']
  reviewsCount: Scalars['Int']
  tags: Array<Scalars['String']>
  clinics: Array<ClinicCard>
}

export type TreatmentSpecialist = {
  __typename?: 'TreatmentSpecialist'
  id: Scalars['Int']
  name: Scalars['String']
  isVerified: Scalars['Boolean']
}

export type TreatmentsResults = {
  __typename?: 'TreatmentsResults'
  total: Scalars['Int']
  items: Array<TreatmentCard>
}

export type UpdateClassificationInput = {
  id?: Maybe<Scalars['Int']>
  parentId?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  generalImage?: Maybe<Scalars['UploadOrPath']>
  octagonImage?: Maybe<Scalars['UploadOrPath']>
  servicesToAdd?: Maybe<Array<Scalars['Int']>>
  servicesToRemove?: Maybe<Array<Scalars['Int']>>
}

export type UpdateClinicInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
}

export type UpdateClinicLocationInput = {
  clinicLocationId?: Maybe<Scalars['Int']>
  clinicLocation: UpdateClinicLocationParamsInput
}

export type UpdateClinicLocationParamsInput = {
  category: ClinicLocationCategory
  type: ClinicLocationType
  name: Scalars['String']
  info?: Maybe<Scalars['String']>
  location?: Maybe<LocationInput>
  contacts?: Maybe<Array<Maybe<ContactInfoInput>>>
}

export type UpdateClinicProfileInput = {
  clinicId: Scalars['Int']
  clinicName?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['Upload']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  insurances?: Maybe<Array<Maybe<Scalars['Int']>>>
}

export type UpdateDistributorInput = {
  id: Scalars['Int']
  name: Scalars['String']
}

export type UpdateDistributorLocationInput = {
  distributorId: Scalars['Int']
  name: Scalars['String']
  type: Scalars['String']
  info?: Maybe<Scalars['String']>
  specialty?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
}

export type UpdateInsuranceInput = {
  id: Scalars['Int']
  type?: Maybe<EInsuranceType>
  description?: Maybe<Scalars['String']>
}

export type UpdateLanguageInput = {
  id: Scalars['Int']
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type UpdateLocationInput = {
  id: Scalars['Int']
  countryCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  address: Scalars['String']
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type UpdateManufacturingCompanyProfileInput = {
  manufacturingCompanyId: Scalars['Int']
  name: Scalars['String']
  businessCategory: Scalars['String']
  registrationNumber?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['Upload']>
  info?: Maybe<Scalars['String']>
  contactInfo?: Maybe<Array<Maybe<ManufacturingCompanyContactInfoInput>>>
}

export type UpdateMedicalServiceInput = {
  medicalServiceId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  tagToAddIds?: Maybe<Array<Scalars['Int']>>
  tagToRemoveIds?: Maybe<Array<Scalars['Int']>>
  specialtyToAddIds?: Maybe<Array<Scalars['Int']>>
  specialtyToRemoveIds?: Maybe<Array<Scalars['Int']>>
}

export type UpdateMedicalSpecialtiesInput = {
  medicalSpecialtyId: Scalars['Int']
  name?: Maybe<MedicalSpecialtyName>
  type?: Maybe<MedicalSpecialtyType>
  profession?: Maybe<Scalars['String']>
}

export type UpdateMedicalWorkerProfileInput = {
  medicalWorkerId: Scalars['Int']
  npi?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  contactInfo?: Maybe<ContactInfoInput>
  professionDesignations: Array<MedicalWorkerProfessionDesignationInput>
  languages?: Maybe<Array<Maybe<LanguagesInput>>>
}

export type UpdateMedicalWorkerServiceInput = {
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['Upload']>
  isActive?: Maybe<Scalars['Boolean']>
  tags?: Maybe<Array<Scalars['String']>>
}

export type UpdateSeoInput = {
  id: Scalars['String']
  url: Scalars['String']
  title: Scalars['String']
  entity?: Maybe<Scalars['String']>
  entityId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  keywords?: Maybe<Scalars['String']>
  additionalParams?: Maybe<Scalars['String']>
}

export type UpdateTagInput = {
  id: Scalars['Int']
  category?: Maybe<TagCategory>
  name?: Maybe<Scalars['String']>
}

export type UpdateUserEmailInput = {
  email?: Maybe<Scalars['String']>
  passwordConfirmation?: Maybe<Scalars['String']>
}

export type UpdateUserPasswordInput = {
  oldPassword?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  passwordConfirmation?: Maybe<Scalars['String']>
}

export type UpdateUserProfileInput = {
  id: Scalars['Int']
  gender?: Maybe<Gender>
  firstName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  phone1?: Maybe<Scalars['String']>
  phone2?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['Upload']>
  dateOfBirth?: Maybe<Scalars['Date']>
}

export type UpdateUserRoleInput = {
  roleId: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  recordName?: Maybe<Scalars['String']>
  scopeToAddIds?: Maybe<Array<Scalars['Int']>>
  scopeToRemoveIds?: Maybe<Array<Scalars['Int']>>
}

export type UploadDocumentInput = {
  docType: DocumentType
  name: Scalars['String']
  referrerType: DocumentReferrerType
  referrerId?: Maybe<Scalars['Int']>
  fileOrLink: Scalars['DocumentFileRef']
}

export type UserProfile = {
  __typename?: 'UserProfile'
  id: Scalars['Int']
  firstName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone1?: Maybe<Scalars['String']>
  phone2?: Maybe<Scalars['String']>
  email: Scalars['String']
  gender?: Maybe<Gender>
}

export type UserProfileInput = {
  firstName: Scalars['String']
  middleName?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  phone: Scalars['String']
  email: Scalars['String']
  gender?: Maybe<Gender>
}

export type UserProfileParamsInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  middleName: Scalars['String']
  phone: Scalars['String']
  email: Scalars['String']
  gender: Gender
}

export type UserRole = {
  __typename?: 'UserRole'
  id: Scalars['Int']
  displayName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  recordName?: Maybe<Scalars['String']>
  scopes?: Maybe<Array<Scalars['String']>>
}

export type VerifyTfaInput = {
  token: Scalars['String']
}

export type VerifyTfaPayload = {
  __typename?: 'VerifyTFAPayload'
  recoveryCodes: Array<Scalars['String']>
}

export const namedOperations = {
  Query: {
    GetClinicProfile: 'GetClinicProfile',
    GetClinicLocationTypes: 'GetClinicLocationTypes',
    GetClinicCategories: 'GetClinicCategories',
    GetAllCalssifications: 'GetAllCalssifications',
    GetAllDesignations: 'GetAllDesignations',
    AllLocations: 'AllLocations',
    GetSpecialistDesignations: 'GetSpecialistDesignations',
    GetFavoriteServicesCards: 'GetFavoriteServicesCards',
    GetFavoriteSpecialistCards: 'GetFavoriteSpecialistCards',
    GetFavoriteClinicLocationsCards: 'GetFavoriteClinicLocationsCards',
    GetAllInsurances: 'GetAllInsurances',
    GetAllLocations: 'GetAllLocations',
    GetServiceSpecialistInArea: 'GetServiceSpecialistInArea',
    GetProfile: 'GetProfile',
    GetPopularService: 'GetPopularService',
    GetAllSpecialist: 'GetAllSpecialist',
    GetSpecialistProfile: 'GetSpecialistProfile',
  },
  Mutation: {
    Login: 'Login',
    RegisterAs: 'RegisterAs',
    ConfirmRegistration: 'ConfirmRegistration',
    UpdateClinicProfile: 'UpdateClinicProfile',
    UpdateClinic: 'UpdateClinic',
    CreateClinicLocation: 'CreateClinicLocation',
    UpdateClinicLocation: 'UpdateClinicLocation',
    DeleteClinicLocation: 'DeleteClinicLocation',
    InviteSpecialists: 'InviteSpecialists',
    RemoveSpecialistFromLocation: 'RemoveSpecialistFromLocation',
    RejectSpecialistRequestToJoinClinicLocation: 'RejectSpecialistRequestToJoinClinicLocation',
    AcceptSpecialistRequestToJoinClinicLocation: 'AcceptSpecialistRequestToJoinClinicLocation',
    UploadDocument: 'UploadDocument',
    DeleteDocument: 'DeleteDocument',
    AddFavoriteCard: 'AddFavoriteCard',
    RemoveFavoriteCard: 'RemoveFavoriteCard',
    CreateLocation: 'CreateLocation',
    UpdateLocation: 'UpdateLocation',
    DeleteLocation: 'DeleteLocation',
    UpdateUserProfile: 'UpdateUserProfile',
    CreateMedicalService: 'CreateMedicalService',
    UpdateMedicalService: 'UpdateMedicalService',
    DeleteMedicalService: 'DeleteMedicalService',
    UpdateSpecialistProfile: 'UpdateSpecialistProfile',
    JoinClinicLocation: 'JoinClinicLocation',
    AcceptClinicLocationInvitation: 'AcceptClinicLocationInvitation',
    RejectClinicLocationInvitation: 'RejectClinicLocationInvitation',
  },
  Fragment: {
    ClinicProps: 'ClinicProps',
    UserProps: 'UserProps',
  },
}
export type LoginMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type LoginMutation = { __typename?: 'Mutation' } & {
  login?: Maybe<
    { __typename?: 'LoginPayload' } & Pick<LoginPayload, 'accessToken' | 'expiresAt' | 'refreshToken' | 'requiresTFA'>
  >
}

export type RegisterAsMutationVariables = Exact<{
  profile: UserProfileInput
  clinic?: Maybe<ClinicProfileInput>
  medicalWorker?: Maybe<MedicalWorkerProfileInput>
  manufacturingCompany?: Maybe<ManufacturingCompanyInput>
}>

export type RegisterAsMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'registerAs'>

export type ConfirmRegistrationMutationVariables = Exact<{
  password: Scalars['String']
  token: Scalars['String']
}>

export type ConfirmRegistrationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'confirmRegistration'>

export type GetClinicProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetClinicProfileQuery = { __typename?: 'Query' } & {
  clinic?: Maybe<{ __typename?: 'CurrentClinic' } & ClinicPropsFragment>
}

export type ClinicPropsFragment = { __typename?: 'CurrentClinic' } & Pick<
  CurrentClinic,
  'id' | 'name' | 'logo' | 'website' | 'npi' | 'info' | 'insurances'
> & { documents?: Maybe<Array<{ __typename?: 'Document' } & Pick<Document, 'id' | 'name' | 'fileRef' | 'type'>>> }

export type UpdateClinicProfileMutationVariables = Exact<{
  clinicId: Scalars['Int']
  clinicName?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['Upload']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  insurances?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>
}>

export type UpdateClinicProfileMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateClinicProfile'>

export type UpdateClinicMutationVariables = Exact<{
  id: Scalars['Int']
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  npi?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
}>

export type UpdateClinicMutation = { __typename?: 'Mutation' } & {
  updateClinic?: Maybe<
    { __typename?: 'ClinicPayload' } & Pick<ClinicPayload, 'id' | 'name' | 'logo' | 'website' | 'npi' | 'info'>
  >
}

export type CreateClinicLocationMutationVariables = Exact<{
  clinicId: Scalars['Int']
  clinicLocation: CreateClinicLocationParamsInput
}>

export type CreateClinicLocationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createClinicLocation'>

export type UpdateClinicLocationMutationVariables = Exact<{
  clinicLocationId?: Maybe<Scalars['Int']>
  clinicLocation: UpdateClinicLocationParamsInput
}>

export type UpdateClinicLocationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateClinicLocation'>

export type DeleteClinicLocationMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>
}>

export type DeleteClinicLocationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteClinicLocation'>

export type InviteSpecialistsMutationVariables = Exact<{
  clinicLocationId: Scalars['Int']
  medicalWorkerIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  newMedicalWorker?: Maybe<CreateAndInviteMedicalWorkerInput>
}>

export type InviteSpecialistsMutation = { __typename?: 'Mutation' } & {
  inviteMedicalWorkers?: Maybe<
    { __typename?: 'InviteMedicalWorkersPayload' } & Pick<InviteMedicalWorkersPayload, 'sentCount'> & {
        sentFails?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvitationContactDetails' } & Pick<
                InvitationContactDetails,
                'id' | 'firstName' | 'lastName'
              >
            >
          >
        >
      }
  >
}

export type RemoveSpecialistFromLocationMutationVariables = Exact<{
  clinicLocationId: Scalars['Int']
  medicalWorkerId: Scalars['Int']
}>

export type RemoveSpecialistFromLocationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'detachMedicalWorkerFromClinicLocation'
>

export type RejectSpecialistRequestToJoinClinicLocationMutationVariables = Exact<{
  invitationId: Scalars['String']
}>

export type RejectSpecialistRequestToJoinClinicLocationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'rejectMedicalWorkerRequestToJoinClinicLocation'
>

export type AcceptSpecialistRequestToJoinClinicLocationMutationVariables = Exact<{
  invitationId: Scalars['String']
}>

export type AcceptSpecialistRequestToJoinClinicLocationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'acceptMedicalWorkerRequestToJoinClinicLocation'
>

export type GetClinicLocationTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetClinicLocationTypesQuery = { __typename?: 'Query' } & {
  items: Array<{ __typename?: 'KeyTitleItem' } & { value: KeyTitleItem['key']; label: KeyTitleItem['title'] }>
}

export type GetClinicCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetClinicCategoriesQuery = { __typename?: 'Query' } & {
  items: Array<{ __typename?: 'KeyTitleItem' } & { value: KeyTitleItem['key']; label: KeyTitleItem['title'] }>
}

export type GetAllCalssificationsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllCalssificationsQuery = { __typename?: 'Query' } & {
  classifications: Array<
    { __typename?: 'ClassificationsQuery' } & Pick<ClassificationsQuery, 'id' | 'parentId' | 'title'>
  >
}

export type GetAllDesignationsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllDesignationsQuery = { __typename?: 'Query' } & {
  listProfessionDesignations: { __typename?: 'ListProfessionDesignationsPayload' } & {
    items?: Maybe<
      Array<
        { __typename?: 'ProfessionDesignation' } & {
          value: ProfessionDesignation['id']
          label: ProfessionDesignation['title']
        }
      >
    >
  }
}

export type AllLocationsQueryVariables = Exact<{ [key: string]: never }>

export type AllLocationsQuery = { __typename?: 'Query' } & {
  allLocations: Array<{ __typename?: 'Location' } & Pick<Location, 'id'>>
}

export type GetSpecialistDesignationsQueryVariables = Exact<{ [key: string]: never }>

export type GetSpecialistDesignationsQuery = { __typename?: 'Query' } & {
  listProfessionDesignations: { __typename?: 'ListProfessionDesignationsPayload' } & Pick<
    ListProfessionDesignationsPayload,
    'total'
  > & {
      items?: Maybe<
        Array<{ __typename?: 'ProfessionDesignation' } & Pick<ProfessionDesignation, 'id' | 'title' | 'description'>>
      >
    }
}

export type UploadDocumentMutationVariables = Exact<{
  docType: DocumentType
  name: Scalars['String']
  referrerType: DocumentReferrerType
  referrerId?: Maybe<Scalars['Int']>
  fileOrLink: Scalars['DocumentFileRef']
}>

export type UploadDocumentMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'uploadDocument'>

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['Int']
  referrerType: DocumentReferrerType
  referrerId?: Maybe<Scalars['Int']>
}>

export type DeleteDocumentMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteDocument'>

export type GetFavoriteServicesCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetFavoriteServicesCardsQuery = { __typename?: 'Query' } & {
  getFavoriteCards?: Maybe<
    { __typename?: 'FavoriteCards' } & {
      services: Array<
        { __typename?: 'MedicalWorkerServicePayload' } & Pick<
          MedicalWorkerServicePayload,
          'id' | 'name' | 'note' | 'image' | 'constTags' | 'editableTags' | 'specialties'
        >
      >
    }
  >
}

export type GetFavoriteSpecialistCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetFavoriteSpecialistCardsQuery = { __typename?: 'Query' } & {
  getFavoriteCards?: Maybe<
    { __typename?: 'FavoriteCards' } & {
      medicalWorkers: Array<
        { __typename?: 'MedicalWorker' } & Pick<
          MedicalWorker,
          'id' | 'name' | 'npi' | 'email' | 'licenseNumber' | 'isVerified'
        >
      >
    }
  >
}

export type GetFavoriteClinicLocationsCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetFavoriteClinicLocationsCardsQuery = { __typename?: 'Query' } & {
  getFavoriteCards?: Maybe<
    { __typename?: 'FavoriteCards' } & {
      clinicLocations: Array<
        { __typename?: 'ClinicLocation' } & Pick<ClinicLocation, 'id' | 'category' | 'type' | 'name' | 'info'> & {
            location: { __typename?: 'Location' } & Pick<
              Location,
              'id' | 'countryCode' | 'city' | 'state' | 'zip' | 'address' | 'coords'
            >
          }
      >
    }
  >
}

export type AddFavoriteCardMutationVariables = Exact<{
  type?: Maybe<FavoriteType>
  id: Scalars['Int']
}>

export type AddFavoriteCardMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'addFavoriteCard'>

export type RemoveFavoriteCardMutationVariables = Exact<{
  type?: Maybe<FavoriteType>
  id: Scalars['Int']
}>

export type RemoveFavoriteCardMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'removeFavoriteCard'>

export type GetAllInsurancesQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>
  type?: Maybe<EInsuranceType>
  description?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}>

export type GetAllInsurancesQuery = { __typename?: 'Query' } & {
  insurances?: Maybe<
    { __typename?: 'InsurancesList' } & Pick<InsurancesList, 'total'> & {
        items: Array<{ __typename?: 'Insurance' } & Pick<Insurance, 'id' | 'type' | 'description'>>
      }
  >
}

export type GetAllLocationsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllLocationsQuery = { __typename?: 'Query' } & {
  allLocations: Array<
    { __typename?: 'Location' } & Pick<Location, 'id' | 'countryCode' | 'city' | 'state' | 'zip' | 'address' | 'coords'>
  >
}

export type CreateLocationMutationVariables = Exact<{
  countryCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  address: Scalars['String']
  coords?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>
}>

export type CreateLocationMutation = { __typename?: 'Mutation' } & {
  createLocation?: Maybe<
    { __typename?: 'LocationPayload' } & Pick<
      LocationPayload,
      'id' | 'countryCode' | 'city' | 'state' | 'zip' | 'address' | 'coords'
    >
  >
}

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['Int']
  countryCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  address: Scalars['String']
  coords?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>
}>

export type UpdateLocationMutation = { __typename?: 'Mutation' } & {
  updateLocation?: Maybe<
    { __typename?: 'LocationPayload' } & Pick<
      LocationPayload,
      'id' | 'countryCode' | 'city' | 'state' | 'zip' | 'address' | 'coords'
    >
  >
}

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type DeleteLocationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteLocation'>

export type UpdateUserProfileMutationVariables = Exact<{
  id: Scalars['Int']
  gender?: Maybe<Gender>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  suffix?: Maybe<Scalars['String']>
  phone1?: Maybe<Scalars['String']>
  phone2?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['Upload']>
  dateOfBirth?: Maybe<Scalars['Date']>
}>

export type UpdateUserProfileMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateUserProfile'>

export type GetServiceSpecialistInAreaQueryVariables = Exact<{
  location?: Maybe<LocationSearchInput>
  filter: FilterSearchInput
}>

export type GetServiceSpecialistInAreaQuery = { __typename?: 'Query' } & {
  search: { __typename?: 'SearchResults' } & {
    specialists?: Maybe<
      { __typename?: 'SpecialistsResults' } & Pick<SpecialistsResults, 'total'> & {
          items: Array<
            { __typename?: 'SpecialistCard' } & Pick<
              SpecialistCard,
              'id' | 'name' | 'specialty' | 'image' | 'isVerified' | 'rating' | 'isExpert' | 'description'
            > & {
                contacts: { __typename?: 'ContactInfo' } & Pick<
                  ContactInfo,
                  | 'id'
                  | 'personalPhone'
                  | 'workPhone'
                  | 'personalEmail'
                  | 'workEmail'
                  | 'preferredChannelCall'
                  | 'preferredChannelText'
                  | 'preferredChannelEmail'
                >
              }
          >
        }
    >
    treatments?: Maybe<
      { __typename?: 'TreatmentsResults' } & Pick<TreatmentsResults, 'total'> & {
          items: Array<
            { __typename?: 'TreatmentCard' } & Pick<
              TreatmentCard,
              'id' | 'name' | 'image' | 'isExpert' | 'rating' | 'price' | 'description'
            > & {
                specialist: { __typename?: 'TreatmentSpecialist' } & Pick<
                  TreatmentSpecialist,
                  'id' | 'name' | 'isVerified'
                >
              }
          >
        }
    >
  }
}

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileQuery = { __typename?: 'Query' } & {
  me?: Maybe<{ __typename?: 'CurrentUser' } & UserPropsFragment>
}

export type UserPropsFragment = { __typename?: 'CurrentUser' } & Pick<
  CurrentUser,
  'id' | 'firstName' | 'lastName' | 'middleName' | 'email' | 'roles'
>

export type GetPopularServiceQueryVariables = Exact<{ [key: string]: never }>

export type GetPopularServiceQuery = { __typename?: 'Query' } & {
  allMedicalServices: Array<
    { __typename?: 'MedicalService' } & Pick<MedicalService, 'id' | 'name' | 'note' | 'image' | 'tags' | 'specialties'>
  >
}

export type CreateMedicalServiceMutationVariables = Exact<{
  key: Scalars['String']
  name: Scalars['String']
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  tagIds: Array<Scalars['Int']> | Scalars['Int']
  specialtyIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type CreateMedicalServiceMutation = { __typename?: 'Mutation' } & {
  createMedicalService?: Maybe<
    { __typename?: 'MedicalServicePayload' } & Pick<
      MedicalServicePayload,
      'id' | 'name' | 'note' | 'image' | 'isActive' | 'tags' | 'specialties'
    >
  >
}

export type UpdateMedicalServiceMutationVariables = Exact<{
  medicalServiceId: Scalars['Int']
  name?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  tagToAddIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  tagToRemoveIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  specialtyToAddIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
  specialtyToRemoveIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type UpdateMedicalServiceMutation = { __typename?: 'Mutation' } & {
  updateMedicalService?: Maybe<
    { __typename?: 'MedicalServicePayload' } & Pick<
      MedicalServicePayload,
      'id' | 'name' | 'note' | 'image' | 'isActive' | 'tags' | 'specialties'
    >
  >
}

export type DeleteMedicalServiceMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>
}>

export type DeleteMedicalServiceMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteMedicalService'>

export type GetAllSpecialistQueryVariables = Exact<{ [key: string]: never }>

export type GetAllSpecialistQuery = { __typename?: 'Query' } & {
  allMedicalWorkers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MedicalWorker' } & Pick<
          MedicalWorker,
          'id' | 'name' | 'npi' | 'email' | 'licenseNumber' | 'isVerified'
        >
      >
    >
  >
}

export type GetSpecialistProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetSpecialistProfileQuery = { __typename?: 'Query' } & {
  currentMedicalWorkerProfile?: Maybe<
    { __typename?: 'CurrentMedicalWorker' } & Pick<
      CurrentMedicalWorker,
      'id' | 'npi' | 'licenseNumber' | 'description' | 'isVerified'
    > & {
        contactInfo?: Maybe<
          { __typename?: 'ContactInfo' } & Pick<
            ContactInfo,
            | 'id'
            | 'personalPhone'
            | 'workPhone'
            | 'personalEmail'
            | 'workEmail'
            | 'preferredChannelCall'
            | 'preferredChannelText'
            | 'preferredChannelEmail'
          >
        >
        professionDesignations: Array<
          { __typename?: 'MedicalWorkerProfessionDesignation' } & Pick<
            MedicalWorkerProfessionDesignation,
            'id' | 'position' | 'title' | 'description'
          >
        >
        clinicLocations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ClinicLocation' } & Pick<ClinicLocation, 'id' | 'category' | 'type' | 'name' | 'info'> & {
                  location: { __typename?: 'Location' } & Pick<
                    Location,
                    'id' | 'countryCode' | 'city' | 'state' | 'zip' | 'address' | 'coords'
                  >
                }
            >
          >
        >
        documents?: Maybe<
          Array<Maybe<{ __typename?: 'Document' } & Pick<Document, 'id' | 'name' | 'fileRef' | 'type'>>>
        >
        profile?: Maybe<
          { __typename?: 'UserProfile' } & Pick<
            UserProfile,
            'id' | 'firstName' | 'middleName' | 'lastName' | 'phone1' | 'phone2' | 'email' | 'gender'
          >
        >
      }
  >
}

export type UpdateSpecialistProfileMutationVariables = Exact<{
  medicalWorkerId: Scalars['Int']
  npi?: Maybe<Scalars['String']>
  licenseNumber?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  contactInfo?: Maybe<ContactInfoInput>
  professionDesignations: Array<MedicalWorkerProfessionDesignationInput> | MedicalWorkerProfessionDesignationInput
  languages?: Maybe<Array<Maybe<LanguagesInput>> | Maybe<LanguagesInput>>
}>

export type UpdateSpecialistProfileMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateMedicalWorkerProfile'>

export type JoinClinicLocationMutationVariables = Exact<{
  clinicLocationId: Scalars['Int']
}>

export type JoinClinicLocationMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'joinClinicLocation'>

export type AcceptClinicLocationInvitationMutationVariables = Exact<{
  invitationId: Scalars['String']
}>

export type AcceptClinicLocationInvitationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'acceptClinicLocationInvitation'
>

export type RejectClinicLocationInvitationMutationVariables = Exact<{
  invitationId: Scalars['String']
}>

export type RejectClinicLocationInvitationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'rejectClinicLocationInvitation'
>

export const ClinicProps = gql`
  fragment ClinicProps on CurrentClinic {
    id
    name
    logo
    website
    npi
    info
    insurances
    documents {
      id
      name
      fileRef
      type
    }
  }
`
export const UserProps = gql`
  fragment UserProps on CurrentUser {
    id
    firstName
    lastName
    middleName
    email
    roles
  }
`
export const Login = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      accessToken
      expiresAt
      refreshToken
      requiresTFA
    }
  }
`
export const RegisterAs = gql`
  mutation RegisterAs(
    $profile: UserProfileInput!
    $clinic: ClinicProfileInput
    $medicalWorker: MedicalWorkerProfileInput
    $manufacturingCompany: ManufacturingCompanyInput
  ) {
    registerAs(
      input: {
        profile: $profile
        clinic: $clinic
        medicalWorker: $medicalWorker
        manufacturingCompany: $manufacturingCompany
      }
    )
  }
`
export const ConfirmRegistration = gql`
  mutation ConfirmRegistration($password: String!, $token: String!) {
    confirmRegistration(input: { password: $password, token: $token })
  }
`
export const GetClinicProfile = gql`
  query GetClinicProfile {
    clinic: currentClinicProfile {
      ...ClinicProps
    }
  }
  ${ClinicProps}
`
export const UpdateClinicProfile = gql`
  mutation UpdateClinicProfile(
    $clinicId: Int!
    $clinicName: String
    $logo: Upload
    $website: String
    $npi: String
    $info: String
    $insurances: [Int]
  ) {
    updateClinicProfile(
      input: {
        clinicId: $clinicId
        clinicName: $clinicName
        logo: $logo
        website: $website
        npi: $npi
        info: $info
        insurances: $insurances
      }
    )
  }
`
export const UpdateClinic = gql`
  mutation UpdateClinic($id: Int!, $name: String, $logo: String, $website: String, $npi: String, $info: String) {
    updateClinic(input: { id: $id, name: $name, logo: $logo, website: $website, npi: $npi, info: $info }) {
      id
      name
      logo
      website
      npi
      info
    }
  }
`
export const CreateClinicLocation = gql`
  mutation CreateClinicLocation($clinicId: Int!, $clinicLocation: CreateClinicLocationParamsInput!) {
    createClinicLocation(input: { clinicId: $clinicId, clinicLocation: $clinicLocation })
  }
`
export const UpdateClinicLocation = gql`
  mutation UpdateClinicLocation($clinicLocationId: Int, $clinicLocation: UpdateClinicLocationParamsInput!) {
    updateClinicLocation(input: { clinicLocationId: $clinicLocationId, clinicLocation: $clinicLocation })
  }
`
export const DeleteClinicLocation = gql`
  mutation DeleteClinicLocation($id: Int) {
    deleteClinicLocation(id: $id)
  }
`
export const InviteSpecialists = gql`
  mutation InviteSpecialists(
    $clinicLocationId: Int!
    $medicalWorkerIds: [Int!]
    $newMedicalWorker: CreateAndInviteMedicalWorkerInput
  ) {
    inviteMedicalWorkers(
      input: {
        clinicLocationId: $clinicLocationId
        medicalWorkerIds: $medicalWorkerIds
        newMedicalWorker: $newMedicalWorker
      }
    ) {
      sentCount
      sentFails {
        id
        firstName
        lastName
      }
    }
  }
`
export const RemoveSpecialistFromLocation = gql`
  mutation RemoveSpecialistFromLocation($clinicLocationId: Int!, $medicalWorkerId: Int!) {
    detachMedicalWorkerFromClinicLocation(clinicLocationId: $clinicLocationId, medicalWorkerId: $medicalWorkerId)
  }
`
export const RejectSpecialistRequestToJoinClinicLocation = gql`
  mutation RejectSpecialistRequestToJoinClinicLocation($invitationId: String!) {
    rejectMedicalWorkerRequestToJoinClinicLocation(invitationId: $invitationId)
  }
`
export const AcceptSpecialistRequestToJoinClinicLocation = gql`
  mutation AcceptSpecialistRequestToJoinClinicLocation($invitationId: String!) {
    acceptMedicalWorkerRequestToJoinClinicLocation(invitationId: $invitationId)
  }
`
export const GetClinicLocationTypes = gql`
  query GetClinicLocationTypes {
    items: listClinicLocationType {
      value: key
      label: title
    }
  }
`
export const GetClinicCategories = gql`
  query GetClinicCategories {
    items: listClinicLocationCategories {
      value: key
      label: title
    }
  }
`
export const GetAllCalssifications = gql`
  query GetAllCalssifications {
    classifications {
      id
      parentId
      title
    }
  }
`
export const GetAllDesignations = gql`
  query GetAllDesignations {
    listProfessionDesignations {
      items {
        value: id
        label: title
      }
    }
  }
`
export const AllLocations = gql`
  query AllLocations {
    allLocations {
      id
    }
  }
`
export const GetSpecialistDesignations = gql`
  query GetSpecialistDesignations {
    listProfessionDesignations(input: {}) {
      items {
        id
        title
        description
      }
      total
    }
  }
`
export const UploadDocument = gql`
  mutation UploadDocument(
    $docType: DocumentType!
    $name: String!
    $referrerType: DocumentReferrerType!
    $referrerId: Int
    $fileOrLink: DocumentFileRef!
  ) {
    uploadDocument(
      input: {
        docType: $docType
        name: $name
        referrerType: $referrerType
        referrerId: $referrerId
        fileOrLink: $fileOrLink
      }
    )
  }
`
export const DeleteDocument = gql`
  mutation DeleteDocument($id: Int!, $referrerType: DocumentReferrerType!, $referrerId: Int) {
    deleteDocument(input: { id: $id, referrerType: $referrerType, referrerId: $referrerId })
  }
`
export const GetFavoriteServicesCards = gql`
  query GetFavoriteServicesCards {
    getFavoriteCards {
      services {
        id
        name
        note
        image
        constTags
        editableTags
        specialties
      }
    }
  }
`
export const GetFavoriteSpecialistCards = gql`
  query GetFavoriteSpecialistCards {
    getFavoriteCards {
      medicalWorkers {
        id
        name
        npi
        email
        licenseNumber
        isVerified
      }
    }
  }
`
export const GetFavoriteClinicLocationsCards = gql`
  query GetFavoriteClinicLocationsCards {
    getFavoriteCards {
      clinicLocations {
        id
        category
        type
        name
        info
        location {
          id
          countryCode
          city
          state
          zip
          address
          coords
        }
      }
    }
  }
`
export const AddFavoriteCard = gql`
  mutation AddFavoriteCard($type: FavoriteType, $id: Int!) {
    addFavoriteCard(input: { type: $type, id: $id })
  }
`
export const RemoveFavoriteCard = gql`
  mutation RemoveFavoriteCard($type: FavoriteType, $id: Int!) {
    removeFavoriteCard(input: { type: $type, id: $id })
  }
`
export const GetAllInsurances = gql`
  query GetAllInsurances($id: Int, $type: EInsuranceType, $description: String, $offset: Int, $limit: Int) {
    insurances(input: { id: $id, type: $type, description: $description, offset: $offset, limit: $limit }) {
      items {
        id
        type
        description
      }
      total
    }
  }
`
export const GetAllLocations = gql`
  query GetAllLocations {
    allLocations {
      id
      countryCode
      city
      state
      zip
      address
      coords
    }
  }
`
export const CreateLocation = gql`
  mutation CreateLocation(
    $countryCode: String
    $city: String
    $state: String
    $zip: String
    $address: String!
    $coords: [Float]
  ) {
    createLocation(
      input: { countryCode: $countryCode, city: $city, state: $state, zip: $zip, address: $address, coords: $coords }
    ) {
      id
      countryCode
      city
      state
      zip
      address
      coords
    }
  }
`
export const UpdateLocation = gql`
  mutation UpdateLocation(
    $id: Int!
    $countryCode: String
    $city: String
    $state: String
    $zip: String
    $address: String!
    $coords: [Float]
  ) {
    updateLocation(
      input: {
        id: $id
        countryCode: $countryCode
        city: $city
        state: $state
        zip: $zip
        address: $address
        coords: $coords
      }
    ) {
      id
      countryCode
      city
      state
      zip
      address
      coords
    }
  }
`
export const DeleteLocation = gql`
  mutation DeleteLocation($id: Int!) {
    deleteLocation(id: $id)
  }
`
export const UpdateUserProfile = gql`
  mutation UpdateUserProfile(
    $id: Int!
    $gender: Gender
    $firstName: String
    $lastName: String
    $middleName: String
    $suffix: String
    $phone1: String
    $phone2: String
    $email: String
    $name: String
    $photo: Upload
    $dateOfBirth: Date
  ) {
    updateUserProfile(
      input: {
        id: $id
        gender: $gender
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        suffix: $suffix
        phone1: $phone1
        phone2: $phone2
        email: $email
        name: $name
        photo: $photo
        dateOfBirth: $dateOfBirth
      }
    )
  }
`
export const GetServiceSpecialistInArea = gql`
  query GetServiceSpecialistInArea($location: LocationSearchInput, $filter: FilterSearchInput!) {
    search(input: { location: $location, filter: $filter }) {
      specialists {
        total
        items {
          id
          name
          specialty
          image
          isVerified
          rating
          isExpert
          contacts {
            id
            personalPhone
            workPhone
            personalEmail
            workEmail
            preferredChannelCall
            preferredChannelText
            preferredChannelEmail
          }
          description
        }
      }
      treatments {
        total
        items {
          id
          name
          image
          specialist {
            id
            name
            isVerified
          }
          isExpert
          rating
          price
          description
        }
      }
    }
  }
`
export const GetProfile = gql`
  query GetProfile {
    me {
      ...UserProps
    }
  }
  ${UserProps}
`
export const GetPopularService = gql`
  query GetPopularService {
    allMedicalServices {
      id
      name
      note
      image
      tags
      specialties
    }
  }
`
export const CreateMedicalService = gql`
  mutation CreateMedicalService(
    $key: String!
    $name: String!
    $note: String
    $image: String
    $tagIds: [Int!]!
    $specialtyIds: [Int!]!
  ) {
    createMedicalService(
      input: { key: $key, name: $name, note: $note, image: $image, tagIds: $tagIds, specialtyIds: $specialtyIds }
    ) {
      id
      name
      note
      image
      isActive
      tags
      specialties
    }
  }
`
export const UpdateMedicalService = gql`
  mutation UpdateMedicalService(
    $medicalServiceId: Int!
    $name: String
    $note: String
    $image: String
    $tagToAddIds: [Int!]
    $tagToRemoveIds: [Int!]
    $specialtyToAddIds: [Int!]
    $specialtyToRemoveIds: [Int!]
  ) {
    updateMedicalService(
      input: {
        medicalServiceId: $medicalServiceId
        name: $name
        note: $note
        image: $image
        tagToAddIds: $tagToAddIds
        tagToRemoveIds: $tagToRemoveIds
        specialtyToAddIds: $specialtyToAddIds
        specialtyToRemoveIds: $specialtyToRemoveIds
      }
    ) {
      id
      name
      note
      image
      isActive
      tags
      specialties
    }
  }
`
export const DeleteMedicalService = gql`
  mutation DeleteMedicalService($id: Int) {
    deleteMedicalService(id: $id)
  }
`
export const GetAllSpecialist = gql`
  query GetAllSpecialist {
    allMedicalWorkers {
      id
      name
      npi
      email
      licenseNumber
      isVerified
    }
  }
`
export const GetSpecialistProfile = gql`
  query GetSpecialistProfile {
    currentMedicalWorkerProfile {
      id
      npi
      licenseNumber
      description
      isVerified
      contactInfo {
        id
        personalPhone
        workPhone
        personalEmail
        workEmail
        preferredChannelCall
        preferredChannelText
        preferredChannelEmail
      }
      professionDesignations {
        id
        position
        title
        description
      }
      clinicLocations {
        id
        category
        type
        name
        info
        location {
          id
          countryCode
          city
          state
          zip
          address
          coords
        }
      }
      documents {
        id
        name
        fileRef
        type
      }
      profile {
        id
        firstName
        middleName
        lastName
        phone1
        phone2
        email
        gender
      }
    }
  }
`
export const UpdateSpecialistProfile = gql`
  mutation UpdateSpecialistProfile(
    $medicalWorkerId: Int!
    $npi: String
    $licenseNumber: String
    $description: String
    $isActive: Boolean
    $contactInfo: ContactInfoInput
    $professionDesignations: [MedicalWorkerProfessionDesignationInput!]!
    $languages: [LanguagesInput]
  ) {
    updateMedicalWorkerProfile(
      input: {
        medicalWorkerId: $medicalWorkerId
        npi: $npi
        licenseNumber: $licenseNumber
        description: $description
        isActive: $isActive
        contactInfo: $contactInfo
        professionDesignations: $professionDesignations
        languages: $languages
      }
    )
  }
`
export const JoinClinicLocation = gql`
  mutation JoinClinicLocation($clinicLocationId: Int!) {
    joinClinicLocation(clinicLocationId: $clinicLocationId)
  }
`
export const AcceptClinicLocationInvitation = gql`
  mutation AcceptClinicLocationInvitation($invitationId: String!) {
    acceptClinicLocationInvitation(invitationId: $invitationId)
  }
`
export const RejectClinicLocationInvitation = gql`
  mutation RejectClinicLocationInvitation($invitationId: String!) {
    rejectClinicLocationInvitation(invitationId: $invitationId)
  }
`
