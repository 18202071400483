import React, { FC, useMemo } from 'react'
import styles from './button.module.scss'

export enum ButtonsColors {
  SUCCESS = 'success',
  DANGER = 'danger',
  ORANGE = 'orange',
  BLUE = 'blue',
  BLUE_LIGHT = 'blue-light',
  BLUE_OPACITY = 'blue-opacity',
  MAIN = 'main',
  TRANSPARENT_WHITE = 'transparentWhite',
  TRANSPARENT_BLUE = 'transparentBlue',
}

export enum ButtonsSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface ButtonProps {
  text?: string
  color?: ButtonsColors
  size?: ButtonsSizes
  disabled?: boolean
  onClick?: () => void
  leftIcon?: string
  rightIcon?: string
  className?: string
}

export const Button: FC<ButtonProps> = props => {
  const { color, size, text, disabled, onClick, leftIcon, rightIcon, className } = props

  const buttonClassName = useMemo(
    () => ` 
    ${styles[color || ButtonsColors.BLUE]} 
    ${styles[size || ButtonsSizes.LARGE]} 
    ${disabled ? styles.disabled : ''}
  `,
    [disabled, size, color]
  )

  return (
    <div className={`${styles.container} ${className ?? ''}`}>
      <button className={buttonClassName} disabled={disabled} onClick={onClick}>
        {leftIcon && <i className={`icon ${styles.left} ${leftIcon}`} />}
        {text}
        {rightIcon && <i className={`icon ${styles.right} ${rightIcon}`} />}
      </button>
    </div>
  )
}
