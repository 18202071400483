import React, { FC, ReactNode } from 'react'
import { ValidationResult } from '../../../validation/ValidationResult'
import styles from '../../form-components.module.scss'
import FormGroupStyles from './FormGroupStyles.module.scss'

interface Props {
  required?: boolean
  label?: string
  validationResult?: ValidationResult | undefined
  children: ReactNode
  isTouched: boolean
}

export const FormGroup: FC<Props> = props => {
  const { label, required, validationResult, isTouched, children } = props
  const { hasError, messages } = validationResult || {}
  return (
    <div className={`${styles.container} ${FormGroupStyles.container}`}>
      {label && (
        <label className={styles.label}>
          {required && <span>*&nbsp;</span>}
          {label}
        </label>
      )}
      <div className={`${styles.input} ${hasError && isTouched ? styles.inputError : ''} ${styles.inputWrapper}`}>
        {children}
      </div>
      {hasError && isTouched && <p className={styles.textError}>{messages}</p>}
    </div>
  )
}
