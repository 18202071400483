import React, { FC, useMemo } from 'react'
import styles from './statesFooter.module.scss'
import { Slider } from '../Slider'
import { BlockText } from '../../core/models/BlockText'

interface Props {
  states: string[]
  localization: StatesFooterLocalization
}

export const StatesFooter: FC<Props> = props => {
  const { states, localization } = props

  const chunkStates = useMemo(() => chunk(9, states), [])

  return (
    <div className={styles.container} id="statesFooter">
      <div className="container">
        <h2 className={`semi-title`}>
          {localization.title} {states.length} {localization.stateName}
        </h2>
        <p className={`sub-info`}>{localization.text}</p>
        <div className={`${styles.statesContainer} ${styles.divList}`}>
          {chunkStates.map((subStates, key) => (
            <div key={key} className={styles.item}>
              {subStates.map((state, key) => (
                <span className={styles.locationsName} key={key}>
                  {state}
                </span>
              ))}
            </div>
          ))}
        </div>
        <Slider settings={settings} className={`${styles.statesContainer} ${styles.sliderList}`}>
          {chunkStates.map((subStates, key) => (
            <div key={key} className={styles.item}>
              {subStates.map((state, key) => (
                <span className={styles.locationsName} key={key}>
                  {state}
                </span>
              ))}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export interface StatesFooterLocalization extends BlockText {
  title: string
  text: string
  stateName: string
}

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  // variableWidth: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 767.98,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
}

type chunk = string[]

const chunk = (size: number, array: any[]): chunk[] => {
  let chunks = []
  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    chunks[i] = array.slice(i * size, i * size + size)
  }
  return chunks
}
