import type { FC } from 'react'
import React, { useCallback } from 'react'
import styles from './loginModal.module.scss'
import { Modal } from '../Modal'
import { LoginForm, LoginFormData, LoginFormLocalization } from '../forms/LoginForm/LoginForm'
import { useLoginMutation } from '../../graphql/generated/auth.generated'
import { useAuthContext } from '../../core/auth/AuthContext'
import { useRouter } from 'next/router'
import { Path } from '../../static/enums/Path'

interface Props {
  localization: LoginFormLocalization
  visibleModal: boolean
  setVisibleLoginModal: (val: boolean) => void
}

export const LoginModal: FC<Props> = props => {
  const { localization, setVisibleLoginModal, visibleModal } = props

  const [login] = useLoginMutation()
  const { setToken } = useAuthContext()
  const { push } = useRouter()

  const onSubmit = useCallback(
    async (data: LoginFormData) => {
      const res = await login({
        variables: data,
      })

      const accessToken = res.data?.login!.accessToken!
      setToken(accessToken)
      setVisibleLoginModal(false)
      push(Path.SEARCH)
    },
    [login, setToken]
  )

  return (
    <Modal visibleModal={visibleModal} setVisibleLoginModal={setVisibleLoginModal}>
      <div className={styles.container}>
        <LoginForm onSubmit={onSubmit} initialData={undefined} localization={localization} />
      </div>
    </Modal>
  )
}
