import { RegExps } from '../constants/RegExps'
import { BaseValidator, BaseValidationConfig, defaultBaseValidationMessages } from './BaseValidator'
import { ValidationConditionConfig, ValidationParameteredConditionConfig } from './ValidationConditionConfig'
import { parseConfig, parseConfig1, S, VCC1 } from './configFactoryHelper'
import { ArrayUtil } from '../utils/ArrayUtil'
import { ValidationResult } from './ValidationResult'

interface Props {
  shouldExists?: S<string>
  shouldBeEmail?: S<string>
  shouldBeUrl?: S<string>
  shouldBePhoneNumber?: S<string>
  shouldHaveLengthMoreThen?: VCC1<string, number>
  shouldHaveLengthLessThen?: VCC1<string, number>
  shouldContains?: VCC1<string, string>
  shouldNotcontains?: VCC1<string, string>
}

export class StringValidator extends BaseValidator<string, StringValidationConfig> {
  constructor(value: string | undefined, propertyName: string, props: Props) {
    const config = createStringValidationConfig(props)
    super(value, propertyName, config)
  }
  public validate() {
    const messages: string[] = []

    ArrayUtil.addIfExists(messages, this.shouldExists())

    if (messages.length) {
      return new ValidationResult(this.config.shouldExists ? messages : [])
    }

    ArrayUtil.addIfExists(messages, this.shouldBeEmail())
    ArrayUtil.addIfExists(messages, this.shouldBeUrl())
    ArrayUtil.addIfExists(messages, this.shouldBePhoneNumber())
    ArrayUtil.addIfExists(messages, this.shouldHaveLengthMoreThen())
    ArrayUtil.addIfExists(messages, this.shouldContains())
    ArrayUtil.addIfExists(messages, this.shouldNotcontains())

    return new ValidationResult(messages)
  }

  protected shouldExists() {
    const message = super.shouldExists()

    if (message) {
      return message
    }
    const { shouldExists } = this.config

    const test = () => !this.value.replace(/\s/g, '').length

    return this.getMessage(test, shouldExists)
  }

  protected shouldBeEmail() {
    const { shouldBeEmail } = this.config

    const regexp = RegExps.EMAIL

    const test = () => !regexp.test(this.value)

    return this.getMessage(test, shouldBeEmail)
  }

  protected shouldBeUrl() {
    const { shouldBeUrl } = this.config

    const regexp = RegExps.URL

    const test = () => !regexp.test(this.value)

    return this.getMessage(test, shouldBeUrl)
  }

  protected shouldBePhoneNumber() {
    const { shouldBePhoneNumber } = this.config

    const regexp = RegExps.PHONE

    const test = () => !regexp.test(this.value)

    return this.getMessage(test, shouldBePhoneNumber)
  }

  protected shouldHaveLengthMoreThen() {
    const { shouldHaveLengthMoreThen } = this.config

    const test = (length: number) => this.value.length <= length

    return this.getParametredMessage(test, shouldHaveLengthMoreThen)
  }

  protected shouldHaveLengthLessThen() {
    const { shouldHaveLengthLessThen } = this.config

    const test = (length: number) => this.value.length >= length

    return this.getParametredMessage(test, shouldHaveLengthLessThen)
  }
  protected shouldContains() {
    const { shouldContains } = this.config

    const test = (expected: string) => !new RegExp(expected).test(this.value)

    return this.getParametredMessage(test, shouldContains)
  }

  protected shouldNotcontains() {
    const { shouldNotcontains: shouldDoNotcontains } = this.config

    const test = (expected: string) => new RegExp(expected).test(this.value)

    return this.getParametredMessage(test, shouldDoNotcontains)
  }
}

interface StringValidationConfig extends BaseValidationConfig<string> {
  shouldBeEmail?: ValidationConditionConfig<string>
  shouldBeUrl?: ValidationConditionConfig<string>
  shouldBePhoneNumber?: ValidationConditionConfig<string>
  shouldHaveLengthMoreThen?: ValidationParameteredConditionConfig<string, number>
  shouldHaveLengthLessThen?: ValidationParameteredConditionConfig<string, number>
  shouldContains?: ValidationParameteredConditionConfig<string, string>
  shouldNotcontains?: ValidationParameteredConditionConfig<string, string>
}

const createStringValidationConfig = (props: Props): StringValidationConfig => {
  const config: StringValidationConfig = {}

  config.shouldExists = parseConfig(props.shouldExists, dm.shouldExists)
  config.shouldBeEmail = parseConfig(props.shouldBeEmail, dm.shouldBeEmail)
  config.shouldBeUrl = parseConfig(props.shouldBeUrl, dm.shouldBeUrl)
  config.shouldBePhoneNumber = parseConfig(props.shouldBePhoneNumber, dm.shouldBePhoneNumber)
  config.shouldHaveLengthMoreThen = parseConfig1(props.shouldHaveLengthMoreThen, dm.shouldHaveLengthMoreThen)
  config.shouldHaveLengthLessThen = parseConfig1(props.shouldHaveLengthLessThen, dm.shouldHaveLengthLessThen)
  config.shouldContains = parseConfig1(props.shouldContains, dm.shouldContains)
  config.shouldNotcontains = parseConfig1(props.shouldNotcontains, dm.shouldNotcontains)

  return config
}

const dm = {
  shouldBeEmail: '{value} is not valid email for property {propertyName}',
  shouldBeUrl: '{value} is not valid url for property {propertyName}',
  shouldBePhoneNumber: '{value} is not valid phone number for property {propertyName}',
  shouldHaveLengthMoreThen: (value: string, propertyName: string, length: number) =>
    `Minimum length of ${propertyName} is ${length} buy it's ${value.length}`,
  shouldHaveLengthLessThen: (value: string, propertyName: string, length: number) =>
    `Maximum length of ${propertyName} is ${length} buy it's ${value.length}`,
  shouldContains: (_: string, propertyName: string, str: string) => `${propertyName} should contains ${str}`,
  shouldNotcontains: (_: string, propertyName: string, str: string) => `${propertyName} shouldn't contains ${str}`,
  ...defaultBaseValidationMessages,
}
